import React from 'react'
import { Header } from '../templates/Header'
import { Footer } from '../templates/Footer'

export const Contactus = () => {
    return (
        <>
            <Header />
            <div className="contact_depath">
                <div className="container">
                    <div className="section-header text-center">
                        <p>Get In Touch</p>
                        <h2>Contact For Any Query</h2>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contact_depath-info">
                                <h2>Quick Contact Info</h2>
                                <div className="contact_depath-info-item">
                                    <div className="contact_depath-info-icon fir">
                                        <i className="far fa-clock"></i>
                                    </div>
                                    <div className="contact_depath-info-text">
                                        <h3>Address </h3>
                                        <p>SCF 126 CENTRAL TOWN KAPURTHALA <br></br>
                                            <b>Shallu Arora</b>
                                        </p>
                                    </div>
                                </div>
                                <div className="contact_depath-info-item">
                                    <div className="contact_depath-info-icon">
                                        <i className="fa fa-phone-alt"></i>
                                    </div>
                                    <div className="contact_depath-info-text">
                                        <h3>Call Us</h3>
                                        <p>+919056813248</p>
                                    </div>
                                </div>
                                <div className="contact_depath-info-item">
                                    <div className="contact_depath-info-icon">
                                        <i className="far fa-envelope"></i>
                                    </div>
                                    <div className="contact_depath-info-text">
                                        <h3>Email Us</h3>
                                        <p>acehealthcare2023@gmail.com </p>
                                    </div>
                                </div>
                                <ul className="social-media-list">
                                    <li><a href="#" target="_blank" className="contact-icon_deflot">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    </li>
                                    <li><a href="#" target="_blank" className="contact-icon_deflot">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    </li>
                                    <li><a href="#" target="_blank" className="contact-icon_deflot">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                    </li>
                                    <li><a href="#" target="_blank" className="contact-icon_deflot">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    </li>
                                    <li><a href="#" target="_blank" className="contact-icon_deflot">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="contact_depath-form">
                                <div id="success"></div>
                                <form name="sentMessage" id="contact_depathForm" novalidate="novalidate">
                                    <div className="control-group">
                                        <input type="text" className="form-control" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="control-group">
                                        <input type="email" className="form-control" id="email" placeholder="Your Email" required="required" data-validation-required-message="Please enter your email" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="control-group">
                                        <input type="text" className="form-control" id="subject" placeholder="Subject" required="required" data-validation-required-message="Please enter a subject" />
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div className="control-group">
                                        <textarea className="form-control" id="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message"></textarea>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div>
                                        <button className="btn btn-custom" type="submit" id="sendMessageButton">Send Message</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row map_contact_set">
                    <div className="col-md-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d440.29405232264264!2d75.58286769573809!3d31.327052735192268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a5a5c89b7396b%3A0x8bbc24c9e81e207a!2sAce Healthcare%2F3%2C%20Central%20Town%2C%20Jalandhar%2C%20Punjab%20144001!5e0!3m2!1sen!2sin!4v1686809779446!5m2!1sen!2sin" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
