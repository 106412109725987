import React, { useEffect, useState } from 'react'
import CommonHeader from '../common/CommonHeader'
import { Footer } from '../templates/Footer'
import { Accordion, Button, Container } from 'react-bootstrap'
import { OrderAPI } from '../services/apis/Api'

const Order = () => {
    const [OrderApiData, setOrderApiData] = useState([]);

    const getOrderAPIData = async () => {
        try {
            const result = await OrderAPI();
            console.log(result)
            setOrderApiData(result.data)
        } catch (error) {
            console.log('getOrderAPIData' + error);
        };
    };
    useEffect(() => {
        getOrderAPIData();
    }, []);

    return (
        <div>
            <CommonHeader name="Orders" />
            <Container>
                {
                    OrderApiData.map((item) => (
                        <Accordion defaultActiveKey="1" style={{ marginTop: 10 }} key={item.order_id}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div style={{ width: '100%' }}>
                                        <div className='order-top-flex'>
                                        <h6 style={{ color: '#000', fontWeight: '900' }}>Order Number : {item.order_no} </h6>
                                       <div>
                                       {
                                            item.order_otp_confirmed === '0' || item.delivery_otp_confirmed === '0' ?
                                                <div >
                                                    {item.order_otp_confirmed === '0' ? <div style={{ color: 'red', fontSize: 13 }}>Order OTP : {item.order_otp} </div> : null}
                                                    {item.delivery_otp_confirmed === '0' ? <div style={{ color: 'red', fontSize: 13 }}>Delivery OTP : {item.delivery_otp} </div> : null}
                                                </div>
                                                : null
                                        }
                                       </div>
                                        </div>
                                        <div className='order-top-flex'>
                                            <h6 style={{ color: '#000' }}>Order Date : {item.order_date}</h6>
                                            <h6 style={{ color: '#000' }}>Rs. {item.total_amount} </h6>
                                        </div>
                                        <Button  style={{ backgroundColor: item.button_color_code }} color={item.button_color_code}> {item.delivery_status} </Button>{' '}
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className='accordion-body-backgroundcolor'>
                                    {item.item_list.map((items) => (
                                        <div key={items.product_name} >
                                            <div className='order-top-flex'>
                                                <h6 style={{ color: '#8fd736' }}>Product Name : {items.product_name} </h6>
                                                <h6 style={{ color: '#000' }}>Unit Price : {items.unit_price} </h6>
                                            </div>
                                            <div className='order-top-flex'>
                                                <h6 style={{ color: '#000' }}>Product Quantity : {items.quantity} </h6>
                                                <h6 style={{ color: '#000', fontWeight: '600' }}>Rs : {items.amount}</h6>
                                            </div>
                                        </div>
                                    ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    ))
                }
            </Container>
            <Footer />
        </div>
    )
}

export default Order