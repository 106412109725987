import React, { useEffect, useState } from 'react'
import { Container, Navbar } from 'react-bootstrap'
import '../../style/Style.css';
import { useNavigate } from 'react-router-dom';

const CommonHeader = (props) => {
    const [Data, setData] = useState('');
    const navigate = useNavigate();
    const getLocalStorageData = async () => {
        try {
            const storedData = await localStorage.getItem('acekey');
            if (storedData) {
                setData(JSON.parse(storedData));
            } else {
                setData('')
            }
        } catch (error) {
            console.log('getLocalStorageData' + error)
        }
    };


    useEffect(() => {
        getLocalStorageData();
    }, [])
    return (
        <Navbar className="bg-body-grn" >
            <Container >

                <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' ,alignItems:'center'}}>
                    <img
                        alt=""
                        src="../../images/act-care.png"
                        width="150"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <div className="Common-header-heading">{props.name}</div>
                    <div className="login">
                        <div>
                            {Data ?
                                <div className="dropdown medicin">
                                    <button className="dropbtn"><i className="fa-solid fa-user"></i>
                                        <span> {Data.name} </span> <i className="fa-solid fa-caret-down"></i></button>
                                    <div className="dropdown-content">
                                        <a href='#' onClick={() => { navigate('/Layout') }}>Home</a>
                                        <a href='#' onClick={() => { navigate('/Order') }}>Orders</a>
                                       <a href='#' onClick={() => { navigate('/Payment') }}>Payment</a>
                                    </div>
                                </div>
                                :
                                <div className="logged">
                                    <a href="/Login" style={{ color: '#fff', fontSize: 16, fontWeight: '500' }}> <i className="fa-solid fa-user"></i> Sign in / Sign up </a>
                                </div>}
                        </div>
                    </div>
                </div>


            </Container>
        </Navbar>
    )
}

export default CommonHeader