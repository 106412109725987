import React from 'react'
import { Header } from '../templates/Header'
import aboutsImg from '../../images/about_us.jpg'
import thumbImg from '../../images/thumb-ups.png'
import drugsImg from '../../images/drugs.png'
import exclusiveImg from '../../images/exclusive.png'
import { Footer } from '../templates/Footer'
const AboutUs = () => {
  return (
    <>
    <Header/>
    <section className="Shipping-Sec">
    <div className="alway_home_li">
        <ul className="return_back_home">
            <li>
                <a href="">Home</a>
            </li>
            <li><i className="fa-solid fa-angle-right"></i></li>
            <li> About Us</li>
        </ul>
    </div>
    <div className="stat-bnr">
    <img src={aboutsImg}/>
    </div>
    <div className="offset_heading_shep">
        <h3>About Us</h3>
    </div>

</section>

<section className="shipping_conditions policy">
<div className=" ">
<div className="row">
   <div className="col-sm-12 center">
      <span className="abt-txt"> Who we are - India's most convenient online pharmacy </span> 
      <p className="abt-cont">ACE HEALTHCARE  is one of India’s most trusted pharma marketing company, dispensing quality medicines at reasonable prices to over a number of chemists . Acehealthcare  help you to  look after your own health effortlessly with the help of channel partners like chemist,doctors,physiotherapist,veternarians . You can buy and send medicines from any corner of the country - with just a few clicks of the mouse.</p>
      <span className="abt-txt"> What we do – Offer fast online access to medicines with convenient home delivery </span> 
      <p className="abt-cont">At ACE HEALTHCARE, we make a wide range of prescription medicines and other health products conveniently available all across India. Even second and third tier cities and rural villages can now have access to the latest medicines. Since we also offer generic alternatives to most medicines, online buyers can expect significant savings.</p>
      <div className="about-col">
         {/* <div className="abt-images"><img src={thumbImg}/></div> */}
         <h1>CONVENIENCE</h1>
         <p>Heavy traffic, lack of parking, monsoons, shop closed, forgetfulness… these are some of the reasons that could lead to skipping of vital medications. Since taking medicines regularly is a critical component of managing chronic medical conditions, it’s best not to run out of essential medicines. Just log on to acehealthcare.co.in, place your order online and have your medicines delivered to you – without leaving the comfort of your home.</p>
         <p>What’s more, with easy access to reliable drug information, you get to know all about your medicine at acehealthcare.co.in, and once you’re a acehealthcare customer, you’ll get regular refill reminders, so you’ll never again come up short of medicines.</p>
      </div>
      {/* <div className="about-col">
         <div className="abt-images"><img src={drugsImg}/></div>
         <h1>ONE-STOP SHOP</h1>
         <p>At acehealthcare.co, we not only provide you with&nbsp;a wide range of medicines listed under various categories, we also offer a wide choice of OTC products including&nbsp;wellness products, vitamins, diet/fitness supplements, herbal products,&nbsp;pain relievers, diabetic care kits, baby/mother care products, beauty care products and surgical supplies.</p>
      </div> */}
      <div className="about-col">
         {/* <div className="abt-images"><img src={exclusiveImg}/></div> */}
         <h1>TRUST</h1>
         <p>With the highest standards of ethical pharmacy practice, acehealthcare is committed to providing authentic cum affordable medicines as well as a customer service philosophy that is worthy of our valued customers’ loyalty. We offer a superior online shopping experience, which includes ease of navigation and absolute transactional security.</p>
      </div>
   </div>
</div>
   </div>
</section>
<Footer/>
    </>
  )
}

export default AboutUs