import React, { useEffect, useState } from 'react'
import { Header } from './Header'
import { Home } from './Home'
import { Footer } from './Footer'
import Test from './Test'
import { HomeDataApi } from '../services/apis/Api'

export const Layout = () => {
  const [data, setdata] = useState('');

  const handleHomedataApi = async () => {
    try {
        const result = await HomeDataApi(); 
        setdata(result)     
    } catch (error) {
        console.log('handleHomedataApi' + error)
    }
};
useEffect(() => {
  handleHomedataApi();
}, []);
// console.log(data)
  return (
    <>
    <Header />
    <Home Data={data}/>
    <Footer />
    {/* <Test/> */}
    </>
  )
}
