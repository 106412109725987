import React from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap'

export const PaidInvoice = (Paid_invoices) => {
    console.log(Paid_invoices)
    return (
        <div>
            {Paid_invoices.paid_invoices.status !== false ?
                <div>
                    {Paid_invoices.map((item) => (
                        <Container style={{ backgroundColor: '#f8f9fa', padding: '20px', marginTop: 10, borderRadius: '10px' }} key={item.invoice_id}>
                            <Row className="mb-4">
                                <Col className="d-flex align-items-center">
                                    <input type="checkbox" id="checkbox" name="checkbox" />
                                    <h6 style={{ marginLeft: 6 }}>{item.invoice_no}</h6>
                                </Col>
                                <Col className="d-flex justify-content-end align-items-center">
                                    <h6 className="mb-0">Amount: Rs  {item.total_amount}</h6>
                                </Col>
                            </Row>
                            <div>
                                <h6>Due Date:  {item.invoice_date}</h6>
                                <h6>Order Date: {item.due_date}</h6>
                            </div>

                            <Row className="mt-4 justify-content-end">
                                <Button variant="primary" style={{ width: 'auto', backgroundColor: '#8fd736' }} >Show Details</Button>
                            </Row>


                        </Container>
                    ))}

                </div> : <div style={{textAlign:'center'}}>There are no record</div>
            }
        </div>
    )
}
