import React from 'react'
import { Header } from '../templates/Header'
import { Footer } from '../templates/Footer'
const Privacypolicy = () => {
   return (
      <>
         <Header />
         <section className="Shipping-Sec">
            <div className="alway_home_li">
               <ul className="return_back_home Privacy">
                  <li>
                     <a href="">Home</a>
                  </li>
                  <li><i className="fa-solid fa-angle-right"></i></li>
                  <li> Privacy Policy</li>
               </ul>
            </div>
            <div className="stat-bnr">
               <img src="../../images/shipping_static.jpg" />
            </div>
            <div className="offset_heading_shep">
               <h3>Your Privacy - Our Commitment</h3>
            </div>

         </section>
         <section className="shipping_conditions policy">
            <div className=" ">
               <div className="conditions">
                  <ol start="1">
                     <li><strong>General</strong></li>
                  </ol>
                  <ul>
                     <li>At ACE Healthcare, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information gathered through our website and services.</li>
                  </ul>
                  {/* <ul></ul> */}
                  <ol start="2">
                     <li><strong>Information Collection and Use:</strong></li>
                  </ol>
                  <ul>
                     <li>We may collect personal information such as name, email address, phone number, and address when you interact with our website or services, including but not limited to registration, inquiries, purchases, and feedback submission. This information is used to provide the requested services, process transactions, communicate with you, and improve our products and services.</li>
                  </ul>
                  <ol start="6">
                     <li><strong> Cookies Policy</strong></li>
                  </ol>
                  <p>Our website may use cookies to enhance your browsing experience and analyze usage patterns. Cookies are small files stored on your device that track information about your visit. You can control the use of cookies through your browser settings, but please note that disabling cookies may affect the functionality of our website.</p>
                  <ol start="5">
                     <li><strong> Data Security</strong></li>
                  </ol>
                  <p>We employ industry-standard security measures to safeguard your personal information against unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>
                  <ol start="5">
                     <li><strong> Third-Party Links:</strong></li>
                  </ol>
                  <p>Our website may contain links to third-party websites or services that are not operated by ACE Healthcare. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.</p>
                  <ol start="5">
                     <li><strong> Children's Privacy:</strong></li>
                  </ol>
                  <p>ACE Healthcare does not knowingly collect personal information from children under the age of 13. If you believe that we have inadvertently collected personal information from a child under 13, please contact us immediately, and we will take steps to remove the information promptly.</p>
                  <ol start="5">
                     <li><strong> Changes to this Privacy Policy:</strong></li>
                  </ol>
                  <p>We reserve the right to update or revise this Privacy Policy at any time. Any changes will be posted on this page, and the effective date will be updated accordingly. We encourage you to review this Privacy Policy periodically for any updates.</p>
                  <ol start="5">
                     <li><strong> Contact Us:</strong></li>
                  </ol>
                  <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at acehealthcare2023@gmail.com / 9056813248. By using our website and services, you consent to the terms outlined in this Privacy Policy. Thank you for choosing ACE Healthcare..</p>
               </div>
            </div>
         </section>
         <Footer />
      </>
   )
}

export default Privacypolicy