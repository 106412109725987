import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from '../templates/Footer';
import { Header } from '../templates/Header';
import { HomeDataApi } from '../services/apis/Api';

const FeaturedProducts = () => {
    const [selectedOption, setSelectedOption] = useState('');
    const [data, setdata] = useState('');

    const handleHomedataApi = async () => {
        try {
            const result = await HomeDataApi();
            setdata(result)
        } catch (error) {
            console.log('handleHomedataApi' + error)
        }
    };
    useEffect(() => {
        handleHomedataApi();
    }, []);

    const generateMultiples = (value, limit) => {
        const multiples = [];
        for (let i = 1; i <= limit; i++) {
            multiples.push({ label: `${value * i}`, value: `${value * i}`, });
        }
        return multiples;
    };

    const DropdownItems = generateMultiples(5, 10);
    return (
        <div>
            <Header />
            {
                data ?
                data.data.feature_products.map((item, index) => (
                        <div key={index} className="product-details-container">
                            <div className="product-image-container">
                                <img src={item.product_image} alt='img' className="product-image" />
                                {/* <br></br> */}
                            </div>
                            <div className="product-info-container">
                                <h5 style={{ fontWeight: 'bold' }}>{item.pruduct_name}</h5>

                                <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                    <label style={{ color: '#4a8ee6', }}>Price</label>
                                    <strong style={{ fontSize: 18, marginRight: 6 }}>{item.user_price}</strong>
                                    <div style={{ textDecoration: 'line-through' }}>{item.mrp}</div></div>
                                {/* <p><strong>Batch No:</strong> {item.batch_no}</p> */}
                                {/* <p><strong>Expiry Date:</strong> {item.batch_expiry}</p> */}
                                {/* <p><strong>packing:</strong> {item.packing}</p> */}
                                <p><strong>Category Name:</strong> {item.category_name}</p>
                                {/* <p><strong>Description:</strong> {item.description}</p> */}
                                <div className='Selectquantity'>
                                    <label htmlFor="dropdown" >Select quantity:</label>
                                    <div className='Dropdown_input_Box'>
                                        <input
                                            // type="number"
                                            className="quantity-input"
                                            style={{ height: '100%', border: 0, width: 33, paddingLeft: 4 }}
                                            value={item.quantity}
                                        // onChange={(value) => { updateQuantity(product.id, value) }}
                                        />
                                        <select
                                            id="dropdown"
                                            value={selectedOption}
                                            // onChange={(value) => { updateQuantity(product.id, value) }}
                                            style={{ width: 20, border: 0 }}
                                        >
                                            {DropdownItems.map((item) => (
                                                <option key={item.value} >{item.value}</option>
                                            ))}

                                        </select>
                                    </div>
                                </div>
                                <button className="add-to-cart-button" style={{ backgroundColor: '#4a8ee6' }}>Add to Cart</button>
                            </div>
                        </div>
                    ))
                    : null
            }
            <Footer />
        </div>
    );
}

export default FeaturedProducts;
