import React from 'react'
import { Footer } from '../templates/Footer'
import { Header } from '../templates/Header'

export const Cancellationrefundpolicy = () => {
    return (
        <>
            <Header />
            <section className="Shipping-Sec">
                <div className="alway_home_li">
                    <ul className="return_back_home cancell">
                        <li>
                            <a href="">Home</a>
                        </li>
                        <li><i className="fa-solid fa-angle-right"></i></li>
                        <li> Return, Refund and Cancellation Policy</li>
                    </ul>
                </div>
                <div className="stat-bnr">
                    <img src="../../images/shipping_static.jpg" />
                </div>
                <div className="offset_heading_shep CANCELLATION_POLICY">
                    <h3>RETURN, REFUND AND CANCELLATION POLICY</h3>
                </div>

            </section>

            <section className="shipping_conditions policy">
                <div className=" ">
                    <div className="conditions">
                        <p>ACE HEALTHCARE, a company incorporated under the laws of India, having its registered (“ACE HEALTHCARE, we, us”), manages and operates acehealthcare.co.in and a mobile application under the brand name acehealthcare (collectively referred to as the “ Website”) where users can place orders to purchase the Products and/or Services offered by us listed/available on the Website (“User” or “Users” or “you”).</p>
                        <p>We aim to provide the best User experience to our Users beginning from placing order and/or booking an appointment to initiating a return. While transacting on the Website, you can expect a hassle-free experience in returning the Product and cancelling the Services that you have ordered/booked and can rely on us as your preferred shopping destination or Service location.</p>
                        <p>This return, refund, and cancellation refund policy (“Policy”), together with the Terms and Conditions [accessible at  <a href="https://www.acehealthcare.co.in/cancellation-refund-policy" target="_blank" dir="ltr" jslog="91781; 11:%.@.0]; track:vis" rel="noopener nofollow noreferrer" className="oiM5sf">https://www.acehealthcare.co.in/cancellation-refund-policy</a> ] sets out ACE HEALTHCARE’s procedures and policies in accepting: (a) Return, (b) Refund, and (c) Cancellation thereof. Any return or cancellation of Products or Services by you are subject to the terms and conditions set out under this Policy.</p>
                        <p><strong>APPLICABILITY OF POLICY</strong></p>
                        <ul style={{ padding: '0px 0px 0px 14px' }}>
                            <li>(A) By agreeing to use the Website and/or placing a request for purchase of Product(s) and/or Services on the Website, you agree to be bound by the terms contained in this Policy without modification. If you do not agree to the terms contained in this Policy, you are advised not to transact on the Website.</li>
                        </ul>
                        <ul style={{ padding: '0px 0px 0px 14px' }}>
                            <li>(B) Please note that we may from time to time change the terms of the Policy that govern your return, refund and cancellation of an order for Products on the Website. Every time you wish to use the Website, please check the Policy to ensure you understand the terms and conditions that apply at that time.</li>
                        </ul>
                        <p><strong>TERMS FOR CANCELLATION, RETURN AND REFUND OF ORDERS</strong></p>
                        <p style={{ padding: '0px 0px 0px 14px' }}>Please carefully read all the parts to understand the conditions applicable in case of returns, refunds and cancellation of a Product.</p>
                        <ul>
                            <li><strong>(A) RETURN POLICY:</strong></li>
                        </ul>
                        <p>i. Return Policy:</p>
                        <p style={{ padding: '0px 0px 0px 14px' }}>We do our best to ensure that the products you order are delivered according to your specifications. However, should you receive an (a) incomplete order, (b) expired product, (c) damaged product, (d) incorrect product, (e) doctor changes the prescription (f) death of a patient, please notify ACE HEALTHCARE User Support immediately within 10 working days of receiving the products, to ensure prompt resolution. Please note that ACE HEALTHCARE will not accept liability for such delivery issues if you fail to notify us within 10 working days of receipt.</p>
                        <p style={{ padding: '0px 0px 0px 14px' }}>User cannot return non-refundable Product; it is User’s responsibility to check whether the Product falls under returnable/non-returnable category before placing an order.</p>
                        <p style={{ padding: '0px 0px 0px 14px' }}>We also understand that various circumstances may compel you to return a product or products that are not defective ONLY if you notify us within 30 working days of receipt and ACE HEALTHCARE reserves the right to accept or reject the product returned in such scenarios.</p>
                        <p>ii. Return Policy Exceptions:</p>
                        <p style={{ padding: '0px 0px 0px 14px' }}>Please note that we are unable to offer replacements or exchanges for the following product categories: Injections, Health Monitor & Equipment and Ortho Support.</p>
                        <p style={{ padding: '0px 0px 0px 14px' }}>Also, ACE HEALTHCARE  reserves the right to refuse returns (or refunds) for certain products, as marked in the respective product pages as "Note: This item cannot be returned for a refund or exchange".</p>
                        <ul>
                            <li><strong>(B) REFUND POLICY:</strong></li>
                        </ul>
                        <ul>
                            <li>i. Refund Policy:<br></br>At ACE HEALTHCARE, we do our best to ensure that you are completely satisfied with our products. And we are happy to issue a full refund based on the conditions listed below:</li>
                        </ul>
                        <ul>
                            <li>
                            ii. Full Refund Possible If:
                                <ul style={{ padding: '6px 0px 0px 14px' }}>
                                    <li>a. you received a defective item</li>
                                    <li>b. the ordered item(s) is lost or damaged during transit. </li>
                                    <li>c. you have received the product that has crossed the expiry date</li>
                                </ul>
                            </li>
                        </ul>
                        <ul>
                            <li>iii. Please note: Mode of refund may vary depending on circumstances. If the mode of refund is by Credit/Debit Card or Net Banking, please allow 7 to 10 working days for the credit to appear in your account. While we regret any inconvenience caused by this time frame, it is the bank's policy that delays the refund timing, and we have no control over that. If the mode of refund is by wallet, credit should be available within 24 hours.</li>
                        </ul>
                        <ul>
                            <li>iv. Cash on Delivery: In order to process such refunds for COD orders, the User will be required to send an email to acehealthcare2023@gmail.com  or call us at +91 9056813248. Thereafter, the User will receive a refund to his/her wallet or User’s bank account. In case if it is to be refunded to User’s bank account, the User will have to provide the bank account details.</li>
                        </ul>
                        <ul>
                            <li>v. In those cases, the amount shall be refunded back to the source account of the User. If the User chooses to get the amount into the Wallet, then at the time of deactivation of the User's account, the amount that is unused in his/her wallet account will be transferred back to User’s bank account.</li>
                        </ul>
                        <ul>
                            <li>
                            vi. ACE HEALTHCARE  shall directly refund to the User’s bank account during situations such as -
                                <ul style={{ padding: '6px 0px 0px 14px' }}>
                                    <li>a. When excessive price has been charged/collected from the User and amount needs to be refunded;</li>
                                    <li>b. Any other cases where ACE HEALTHCARE has to refund the amount to the User which belongs to the User shall be refunded to original payment mode.</li>
                                </ul>
                            </li>
                        </ul>
                        <ul>
                            <li>
                            vii. If the User chooses to get the refund to the wallet during the above-mentioned scenarios [as per clause B(v)], at the time of deactivation of the User’s account, any amount that is unused in the wallet shall be transferred to the User’s Bank account.
                                <ul style={{ padding: '6px 0px 0px 14px' }}>
                                    <li>a. How to Request a Refund:</li>
                                </ul>
                            </li>
                        </ul>
                        <p>viii. To request a refund, simply email us your order details, including the reason why you're requesting a refund. We take User feedback very seriously and use it to constantly improve our quality of service</p>
                        <ul>
                            <li><strong>(C) CANCELLATION:</strong></li>
                        </ul>
                        <p>i. Product Cancellation:</p>
                        <p style={{ padding: '0px 0px 0px 14px' }}>User may cancel the order of a product before it is out for delivery. On the receipt of order cancellation request, your order will be cancelled with immediate effect.</p>
                        <p><strong>User Complaints</strong></p>
                        <p>If you have any queries, do call our help desk at +91 9056813248, email us at acehealthcare2023@gmail.com, or contact our User support executives through online chat. We're here for you!</p>
                        <p></p>
                        <br></br>
                        {/* <!--- Content Update End--> */}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
