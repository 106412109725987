import React, { useEffect, useState } from 'react';
import CommonHeader from '../common/CommonHeader';
import { CategoryDetails_API, ProductDetails_API } from '../services/apis/Api';
import { useParams } from 'react-router-dom';
import { Footer } from '../templates/Footer';
import { Header } from '../templates/Header';

const CategoryDetails = () => {
  const [ProductData, setProductData] = useState('')
  const [selectedOption, setSelectedOption] = useState('');
  const { Productname } = useParams();
  // console.log(Productname)
  const IdBeforeSlash = Productname.split("/")[0];
  // console.log(IdBeforeSlash);
  const Get_product_details = async () => {
    const result = await CategoryDetails_API(IdBeforeSlash);
     console.log(result.data)
    setProductData(result.data)
  }

  const generateMultiples = (value, limit) => {
    const multiples = [];
    for (let i = 1; i <= limit; i++) {
      multiples.push({ label: `${value * i}`, value: `${value * i}`, });
    }
    return multiples;
  };
  
  const DropdownItems = generateMultiples(5, 10);
  
  const updateQuantity = (itemId, event) => {
    // console.log(itemId,event.target.value)
    const value = event.target.value
    // setSelectedOption(value)
    // dispatch(updateCartItemQuantity(itemId, value));
  };


  // console.log(ProductData)
  useEffect(() => {
    Get_product_details();
  }, [Productname])

  return (
    <div>
      <Header/>
      {
        ProductData ?
        ProductData.map((item,index)=>(
          <div key={index} className="product-details-container">
          <div className="product-image-container">
            <img src={item.product_image} alt='img' className="product-image" />
            {/* <br></br> */}
          </div>
          <div className="product-info-container">
            <h5 style={{fontWeight:'bold'}}>{item.pruduct_name}</h5>
            
            <div style={{ display: 'flex', gap: 5 ,alignItems:'center'}}>
            <label style={{ color: '#4a8ee6', }}>Price</label>
              <strong style={{ fontSize: 18, marginRight: 6 }}>{item.user_price}</strong>
              <div style={{ textDecoration: 'line-through' }}>{item.mrp}</div></div>
            {/* <p><strong>Batch No:</strong> {item.batch_no}</p> */}
            {/* <p><strong>Expiry Date:</strong> {item.batch_expiry}</p> */}
            {/* <p><strong>packing:</strong> {item.packing}</p> */}
            <p><strong>Category Name:</strong> {item.category_name}</p>
            {/* <p><strong>Description:</strong> {item.description}</p> */}
            <div className='Selectquantity'>
              <label htmlFor="dropdown" >Select quantity:</label>
              <div className='Dropdown_input_Box'>
                <input
                  // type="number"
                  className="quantity-input"
                  style={{ height: '100%', border: 0, width: 33, paddingLeft: 4 }}
                  value={item.quantity}
                  // onChange={(value) => { updateQuantity(product.id, value) }}
                />
                <select
                  id="dropdown"
                  value={selectedOption}
                  // onChange={(value) => { updateQuantity(product.id, value) }}
                  style={{ width: 20, border: 0 }}
                >
                  {DropdownItems.map((item) => (
                    <option key={item.value} >{item.value}</option>
                  ))}

                </select>
              </div>
            </div>
            <button className="add-to-cart-button" style={{ backgroundColor: '#4a8ee6' }}>Add to Cart</button>
          </div>
        </div> 
        ))
          : null
      }
      <Footer />
    </div>
  );
}

export default CategoryDetails;
