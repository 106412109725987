import React from 'react'
import { BrowserRouter as Router, Route, Routes,Switch } from 'react-router-dom';
import SignUp from '../Loginscreens/SignUp';
import Login from '../Loginscreens/Login';
import { Header } from '../templates/Header';
import { Layout } from '../templates/Layout';
import AboutUs from '../Footerlinkscreens/AboutUs';
import { News } from '../Footerlinkscreens/News';
import { Termsandconditions } from '../Footerlinkscreens/Termsandconditions';
import Privacypolicy from '../Footerlinkscreens/Privacypolicy';
import { Paymentpolicy } from '../Footerlinkscreens/Paymentpolicy';
import { Cancellationrefundpolicy } from '../Footerlinkscreens/Cancellationrefundpolicy';
import { Shippingdeliverypolicy } from '../Footerlinkscreens/Shippingdeliverypolicy';
import { Contactus } from '../Footerlinkscreens/Contactus';
import { Cart } from '../cart/Cart';
import Order from '../Order/Order';
import Payment from '../Payment/Payment';
import ProductDetails from '../Details/ProductDetails';
import CategoryDetails from '../Details/CategoryDetails';
import FeaturedProducts from '../Details/FeaturedProducts';

export const Appnavigator = () => {
  return (    
      <Routes>
        <Route path='/' element={<Layout />}/>
        <Route path='/Layout' element={<Layout />}/>
        <Route path='/Login' element={<Login />}/>
        <Route path='SignUp' element={<SignUp />}/>
        <Route path='about-us' element={<AboutUs />}/>
        <Route path='news' element={<News />}/>
        <Route path='terms-conditions' element={<Termsandconditions />}/>
        <Route path='privacy-policy' element={<Privacypolicy />}/>
        <Route path='payment-policy' element={<Paymentpolicy />}/>
        <Route path='cancellation-refund-policy' element={<Cancellationrefundpolicy />}/>
        <Route path='shipping-delivery-policy' element={<Shippingdeliverypolicy />}/>
        <Route path='contact-us' element={<Contactus />}/>
        <Route path='Cart' element={<Cart />}/>
        <Route path='Order' element={<Order />}/>
        <Route path='Payment' element={<Payment />}/>
        <Route path='Productdetails/:Productname' element={<ProductDetails />}/>
        <Route path='CategoryDetails/:Productname' element={<CategoryDetails />}/>
        <Route path='feature-Products' element={<FeaturedProducts />}/>
      </Routes>   
  )
}
