import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART_ITEM_QUANTITY } from './cartActionTypes';

const initialState = {
  items: []
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const itemInCartIndex = state.items.findIndex(item => item.id === action.payload.id);

      if (itemInCartIndex !== -1) {
        // Item is already in the cart
        const updatedItems = [...state.items];
        const multiplier = action.payload.quantity || 1;
        updatedItems[itemInCartIndex].quantity += parseInt(multiplier);
        
        return {
          ...state,
          items: updatedItems
        };
      } else {
        // Item is not in the cart, add it
        const multiplier = action.payload.quantity || 1;
        return {
          ...state,
          items: [...state.items, { ...action.payload, quantity: parseInt(multiplier) }]
        };
      }
    case REMOVE_FROM_CART:
      return {
        ...state,
        items: state.items.filter(item => item.id !== action.payload)
      };
      case UPDATE_CART_ITEM_QUANTITY:
        const { itemId, newQuantity } = action.payload;
        return {
          ...state,
          items: state.items.map(item =>
            item.id === itemId ? { ...item, quantity: newQuantity } : item
          )
        };
    default:
      return state;
  }
};

export default cartReducer;
