import React from 'react'
import { Header } from '../templates/Header'
import { Footer } from '../templates/Footer'
export const News = () => {
    return (
        <>
            <Header />
            <section class="Shipping-Sec">
                <div class="alway_home_li">
                    <ul class="return_back_home Privacy">
                        <li>
                            <a href="">Home</a>
                        </li>
                        <li><i class="fa-solid fa-angle-right"></i></li>
                        <li>In the News</li>
                    </ul>
                </div>
                <div class="stat-bnr">
                    <img src="../../images/shipping_static.jpg" />
                </div>
                <div class="offset_heading_shep">
                    <h3>In the News</h3>
                </div>
            </section>
            <section class="shipping_conditions policy">
                <div class=" ">
                    <div id="digital" class="media-cont active">
                        <ul>
                            <li>
                                <h4>10/15/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../images/timesjob1.jpg" /></a>
                                <p><b>How technology &amp; innovation is transforming the future of the workforce? Find here</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">The digital age has transformed every industry today and the ongoing e-pharmacy revolution is not far behind. In fact, technology weaves in the sensibilities and operational capabilities of a traditional pharmacy and gives it completely different outlook, in the process of creating a seamless workflow. </a>
                            </li>
                            <li>
                                <h4>10/15/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../images/timesjob2.jpg" /></a>
                                <p><b>Young techies come together for the 8th edition of Google Devfest Pune</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">Bruce Schwack, Chief Communications Officer for <a href=""> https://www.acehealthcare.com</a> and renowned global marketing and customer experience expert enthralled the Google Developers’ Devfest 2019 crowd in Pune and left them hungry for more after nearly an hour spent regaling the crowd of more than 1,000 Google Developers with ‘stories that became lessons. </a>
                            </li>
                            <li>
                                <h4>10/15/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../images/timesjob3.jpg" /></a>
                                <p><b>Young Techies Come Together For The 8th Edition Of Google Devfest, Pune</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">Over 1000 developers, IT professionals and techies came together at the 8th annual edition of Google Devfest in Pune, Maharashtra. Organised by Google Developer Group, Devfest saw a confluence of the best young minds in Artificial Intelligence, Machine Learning and more. </a>
                            </li>
                            <li>
                                <h4>10/15/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../images/timesjob4.jpg" /></a>
                                <p><b>Young techies come together for the 8th edition of Google Devfest, Pune</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">(MENAFN - ForPressRelease) 40 15th October 2019, Pune:Over 1000 developers, IT professionals and techies came together at the 8th annual edition of Google Devfest in Pune, Maharashtra. Organised by Google Developer Group, Devfest saw a confluence of the best young minds in Artificial Intelligence, Machine Learning and more.</a>
                            </li>
                            <li>
                                <h4>10/09/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../../public/images/timesjob5.jpg" /></a>
                                <p><b>THE acehealthcare PRESCRIPTION FOR GROWTH</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">Anand Pathak, CMO, <a href=""> https://www.acehealthcare.com</a> talks about the brand’s journey, its association with cricketer MS Dhoni, the prospects for growth in the e-pharma market and its plans for consolidation </a>
                            </li>
                            <li>
                                <h4>10/08/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../../public/images/timesjob6.jpg" /></a>
                                <p><b>Pitch CMO Summit: Transparency, choice &amp; control must be in hands of the consumer: Experts</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">The Pitch CMO Chennai 2019 Summit held on Friday saw an interesting panel discussion on 'Privacy vs Personalization' chaired by Ramesh Jude Thomas, President &amp; Chief Knowledge Officer, Equitor Value Advisory. </a>
                            </li>
                            <li>
                                <h4>08/21/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../images/timesjob7.jpg" /></a>
                                <p><b>acehealthcare Launches First Physical Store in Karnataka</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">Online pharmacy player acehealthcare has opened its first brick-and-mortar store in Belgaum, Karnataka. The company is expand its presence across the start by launching 20 such stores by the year 2020. </a>
                            </li>
                            <li>
                                <h4>08/20/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../images/timesjob8.jpg" /></a>
                                <p><b>acehealthcare launches first brick-and-mortar store in Karnataka</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">New Delhi, Aug 19 (PTI) Online pharmacy <a href=""> https://www.acehealthcare.com</a> on Monday said it has opened its first brick-and-mortar store in Karnataka''s Belgaum with plans to launch 20 such stores across the state by 2020.</a>
                            </li>
                            <li>
                                <h4>08/20/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../../public/images/timesjob9.jpg" /></a>
                                <p><b>acehealthcare launches first brick-and-mortar store in Karnataka</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">The launch of acehealthcare Pharmacy in Belgaum is our first step towards building the foundation of a solid healthcare delivery network in Karnataka," <a href=""> https://www.acehealthcare.com</a> founder and CEO Pradeep Dadha said.</a>
                            </li>
                            <li>
                                <h4>08/20/2019</h4>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener"> <img title="" src="../../images/timesjob10.jpg" /></a>
                                <p><b>acehealthcare launches first brick-and-mortar store in Karnataka</b></p>
                                <a class="pressAdd" href="#" target="_blank" rel="noopener">Online pharmacy <a href=""> https://www.acehealthcare.com</a> on Monday said it has opened its first brick-and-mortar store in Karnataka's Belgaum with plans to launch 20 such stores across the state by 2020.</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
  )
}
