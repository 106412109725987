import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '../templates/Header';
import { Footer } from '../templates/Footer';
import { removeFromCart, updateCartItemQuantity } from '../../Redux/cartActions';
import { Add_order_API, CouponCodeAPI, OffersAPI } from '../services/apis/Api';
import CommonHeader from '../common/CommonHeader';
import { useNavigate } from 'react-router-dom';

export const Cart = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const [radioOptions, setRadioOptions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector(state => state.cart.items);
  console.log(products)
  const getGstdata = () => {
    let cgst = 0;
    let igst = 0;
    let sgst = 0;
    let taxtype = '';
    let includTax = '';
    products.forEach(item => {
      cgst += parseInt(item.cgst) + parseInt(item.sgst);
      igst += parseFloat(item.igst);
      taxtype = item.tax_type;
      includTax += item.tax_apply_type
    });
    return { taxtype, includTax, cgst, igst };
  };
  const gstData = getGstdata();
  const getTotal = () => {
    let total = 0;
    let totalTax = 0;
    let gstAmount = 0;
    let inpointCgst = 0
    products.forEach(item => {
      total += item.quantity * item.user_price;
      if (item.tax_apply_type === "included" & item.cgst !== 0.0) {
        let cgst = item.tax_type === 'CGST' ? (parseInt(item.cgst) + parseInt(item.sgst)) : parseInt(item.igst);
        inpointCgst = ((item.user_price - ((item.user_price * 100) / (100 + cgst))) / item.user_price) * 100;
        if (item.tax_type === 'CGST') {
          let taxAmount = (item.user_price * item.quantity) - (inpointCgst / 100 * item.user_price * item.quantity);
          totalTax += taxAmount;
          gstAmount += ((item.user_price * item.quantity) - taxAmount) / 2;
        } else if (item.tax_type === 'IGST') {
          let taxAmount = (item.user_price * item.quantity) - (inpointCgst / 100 * item.user_price * item.quantity);
          totalTax += taxAmount;
          gstAmount += ((item.user_price * item.quantity) - taxAmount);
        }
      } else {
        totalTax = total;
      }

    });
    const formattedGstAmount = gstAmount.toFixed(2);
    const formattedTotalAmount = totalTax.toFixed(2)
    return { formattedTotalAmount, formattedGstAmount, total, inpointCgst };
  };

  const uniqueId = Date.now().toString();
  const orderData = { product_info: [{ Total_Amount: getTotal().total, Order_Remark: 'hello', Unique_id: uniqueId, cartItems: [], }], };
  products.forEach((item) => {
    let Igst = '';
    let Cgst = '';
    let gstAmount = 0;
    let productRate = 0;
    let freeQty = 0
    if (item.quantity >= parseFloat(item.dis1_onqu) && item.quantity < parseFloat(item.dis2_onqu)) {
      freeQty += parseFloat(item.dis1_free);
    } else if (item.quantity >= parseFloat(item.dis2_onqu) && item.quantity < parseFloat(item.dis3_onqu)) {
      freeQty += parseFloat(item.dis2_free);
    } else if (item.quantity >= parseFloat(item.dis3_onqu)) {
      freeQty += parseFloat(item.dis3_free);
    };

    item.tax_type === 'CGST' ? Cgst = item.cgst : Igst = item.igst;
    if (item.tax_apply_type === "included") {
      let cgst = item.tax_type === 'CGST' ? (parseInt(item.cgst) + parseInt(item.sgst)) : parseInt(item.igst);
      let inpointCgst = ((item.user_price - ((item.user_price * 100) / (100 + cgst))) / item.user_price) * 100;
      if (item.tax_type === 'CGST') {
        let taxAmount = (item.user_price) - (inpointCgst / 100 * item.user_price);
        productRate += taxAmount;
        gstAmount += ((item.user_price * item.quantity) - taxAmount * item.quantity) / 2;
      } else if (item.tax_type === 'IGST') {
        let taxAmount = (item.user_price) - (inpointCgst / 100 * item.user_price);
        productRate += taxAmount;
        gstAmount += ((item.user_price * item.quantity) - taxAmount * item.quantity);
      }
    } else {
      productRate += item.user_price
    };

    const carton = {
      Product_ID: item.id,
      Product_Rate: productRate,
      Product_Quantity: item.quantity,
      Free_Quantity: freeQty,
      igst: Igst,
      cgst: Cgst,
      sgst: Cgst,
      igst_amount: item.tax_type === 'IGST' ? gstAmount : 0,
      cgst_amount: item.tax_type === 'CGST' ? gstAmount : 0,
      sgst_amount: item.tax_type === 'CGST' ? gstAmount : 0,
      user_price: item.user_price,
      batch_id: item.batch_id
    };
    orderData.product_info[0].cartItems.push(carton);
  });
  // console.log(orderData)
  // genertatemultiples in cart qty and update qty
  const generateMultiples = (value, limit) => {
    const multiples = [];
    for (let i = 1; i <= limit; i++) {
      multiples.push({ label: `${value * i}`, value: `${value * i}`, });
    }
    return multiples;
  };
  const updateQuantity = (itemId, event) => {
    // console.log(itemId,event.target.value)
    const value = event.target.value
    setSelectedOption(value)
    dispatch(updateCartItemQuantity(itemId, value));
  };
  const handle_offer_Api = async () => {
    try {
      const result = await OffersAPI();
      console.log(result.data)
      setRadioOptions(result.data)
    } catch (error) {
      console.log('handle_offer_Api' + error)
    }
  }
  const handlecouponcode = async (code) => {
    try {
      const result = await CouponCodeAPI(code);
      console.log(result);
      alert(result.message)
    } catch (error) {
      console.log('handlecouponcode' + error)
    }
  };

  const handleAdd_order = async () => {
    try {
      const storedData = await localStorage.getItem('acekey');
      if (storedData) {
        const confirmOrder = window.confirm('Are you sure you want to place this order?');
        if (confirmOrder) {
          const result = await Add_order_API(orderData);
          console.log(result)
          alert(result.message);
        }
      } else {
        alert('You must login before Place order')
        navigate("/Login");
      }
    } catch (error) {
      console.log('handleAdd_order' + error)
    }
  };
  useEffect(() => {
    handle_offer_Api();
  }, [])


  return (
    <>
      <CommonHeader name="Cart" />
      <div className="shopping-cart">
        <div className="cart">
          {products.map(product => {
            const DropdownItems = generateMultiples(product.cart_multiplier, 10);
            return (
              <div key={product.id} className="cart-item">
                <img src={product.product_image} alt={product.pruduct_image} />
                <div className="item-details">
                  <span className="name">{product.pruduct_name}</span>
                  <span className="packing"> {product.packing} {product.category_name}</span>
                  {product.tax_type === 'CGST' ? <span className="rate">Rate: ₹ {(product.user_price) - (getTotal().inpointCgst / 100 * product.user_price).toFixed(2)}</span> : <span className="rate">Rate: ₹ {((product.user_price) - (getTotal().inpointCgst / 100 * product.user_price)).toFixed(2)}</span>}
                  <span className="rate">Value: ₹{product.user_price}</span>
                  <span className="rate">MRP: ₹{product.mrp}</span>
                  {product.tax_type === 'CGST' ? <span className="rate">Amount: ₹ {((product.user_price * product.quantity) - (getTotal().inpointCgst / 100 * product.user_price * product.quantity)).toFixed(2)}</span> : <span className="rate">Amount: ₹ {((product.user_price * product.quantity) - (getTotal().inpointCgst / 100 * product.user_price * product.quantity)).toFixed(2)}</span>}
                  <span className="rate">Net Amount: ₹ {product.user_price * product.quantity}</span>
                </div>
                <div className='batchExpirydiv'>
                  <span className="rate">Batch no: {product.batch_no}</span>
                  <span className="rate">Batch expiry: {product.batch_expiry}</span>
                  <div className='Selectquantity'>
                    <label htmlFor="dropdown" >Select quantity:</label>
                    <div className='Dropdown_input_Box'>
                      <input
                        // type="number"
                        className="quantity-input"
                        style={{ height: '100%', border: 0, width: 33, paddingLeft: 4 }}
                        value={product.quantity}
                        onChange={(value) => { updateQuantity(product.id, value) }}
                      />
                      <select
                        id="dropdown"
                        value={selectedOption}
                        onChange={(value) => { updateQuantity(product.id, value) }}
                        style={{ width: 20, border: 0 }}
                      >
                        {DropdownItems.map((item) => (
                          <option key={item.value} >{item.value}</option>
                        ))}

                      </select>
                    </div>
                  </div>
                  <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                    <button onClick={() => dispatch(removeFromCart(product.id))} className="place-order-button" >Remove From Cart</button>
                  </div>
                </div>
              </div>
            )
          })}
          {products.length > 0 ?
            <div>
              <h6 style={{ color: '#4a8ee6' }}>Offers Available <i className='fa fa-gift'></i></h6>
              {radioOptions.map((item) => (
                <div key={item.id} style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                  <input type='radio' onClick={() => handlecouponcode('GET100')} style={{ color: '#4a8ee6' }} />
                  use <h6 style={{ color: '#4a8ee6' }}>{item.promo_code}</h6>{item.offer_description}
                </div>
              ))}
            </div> : <div style={{ textAlign: 'center', marginTop: 20 }}><h4>card is empty</h4></div>
          }
        </div>
        {products.length > 0 ?
          <div className="order-summary">
            <h4>Order Summary</h4>
            <div>
              <h6>Gross Total</h6>
              <h6>Rs {getTotal().formattedTotalAmount} </h6>
            </div>
            {gstData.taxtype == 'CGST' ?
              <div style={{ flexDirection: 'column' }}>
                <div>
                  <h6>CGST</h6>
                  <h6>Rs {getTotal().formattedGstAmount}</h6>
                </div>
                <div>
                  <h6>SGST</h6>
                  <h6>Rs {getTotal().formattedGstAmount}</h6>
                </div>
              </div>
              :
              <div>
                <h6>IGST</h6>
                <h6>Rs {getTotal().formattedGstAmount}</h6>
              </div>
            }

            <div className="total">
              <h5>Total Amount</h5>
              <h6>Rs {getTotal().total}</h6>
            </div>
            <div>
              <div></div>
              <button onClick={handleAdd_order} className="place-order-button" >Place Order</button>
            </div>
          </div> : null
        }
      </div>
      <Footer />
    </>
  )
}
