import React from 'react'
import { Header } from '../templates/Header'
import { Footer } from '../templates/Footer'

export const Shippingdeliverypolicy = () => {
    return (
        <>
            <Header />
            <section className="Shipping-Sec">
                <div className="alway_home_li">
                    <ul className="return_back_home">
                        <li>
                            <a href="">Home</a>
                        </li>
                        <li><i className="fa-solid fa-angle-right"></i></li>
                        <li>Shipping and Delivery Policy</li>
                    </ul>
                </div>
                <div className="stat-bnr">
                    <img src="../../images/shipping_static.jpg" />
                </div>
                <div className="offset_heading_shep">
                    <h3>DELIVERY AND SHIPPING POLICY</h3>
                </div>

            </section>

            <section className="shipping_conditions odd">
                <div className=" ">
                    <div className="conditions">
                        <ol>
                            <li><strong> GENERAL</strong></li>
                        </ol>
                        <p>1.1 ACE HEALTHCARE  a company incorporated under the laws of India, having its registered (“ACE HEALTHCARE ”, “we”, “us” or “our”), manages and operates  <a href="acehealthcare.co.in">acehealthcare.co.in</a> and a mobile application under the brand name acehealthcare (collectively referred to as the “Website”) where you can place orders to purchase the Products and/or Services offered by us listed/available on the Website (“User” or “Users” or “you”).</p>
                        <p>1.2 This delivery and shipping policy (“Policy”), together with the Terms and Conditions accessible at<a href="https://www.acehealthcare.co.in/shipping-delivery-policy" target="_blank" dir="ltr" jslog="91781; 11:%.@.0]; track:vis" rel="noopener nofollow noreferrer" className="oiM5sf">https://www.acehealthcare.co.in/shipping-delivery-policy</a> sets out our policies and procedures towards delivery and shipping of Products purchased on the Website.</p>
                        <p>1.3 We provide shipping and delivery of our Products in North India . We aim to provide the best customer experience for you by tying-up and partnering with leading logistics service providers to handle your order in the best possible way and to ensure that you have a hassle-free experience in receiving the Product that you have ordered from the Website. We make all commercially reasonable endeavours to ensure that the Products are delivered to you in a timely fashion.</p>
                        <p>1.4 By agreeing to use the Website and/ or purchasing a Product on the Website, you agree to be bound by the terms of this Policy without modification. We encourage you to read and understand the terms of this Policy every time you visit the Website If you do not agree to the terms contained in this Policy, you are advised not to use, access or transact on the Website.</p>
                        <ol start="2">
                            <li ><strong> TERMS OF SHIPPING AND DELIVERY</strong></li>
                        </ol>
                        <p>2.1 We partner with third party logistic service providers ("Logistic Partners") in order to effectuate Product shipping and delivery to you. We shall provide the details of the Logistic Partners which will be responsible for processing the shipping and delivery of any Product(s) purchased by you on the Website at the time such Product is processed and successfully handed over to the Logistic Partner by us. Our standard dispatch timelines range between 24 (twenty four) hours from the time the Product Order has been placed by you on the Website. In any case, the user will be provided with an estimated timeline for the delivery of the Product purchased from the Website. This estimated timeline shall be notified to the user on the order confirmation page displayed at the time the order is confirmed by us. We will also share details about your orders pursuant to their dispatch on the e-mail ID and/or mobile number provided by you/ registered with us. You agree and understand that though we effectuate Product delivery to the Users through our Logistic Partners, we reserve the right to ship and deliver the Products on our own without engaging any Logistic Partners or third party service providers.</p>
                        <p>2.2 The Delivery charges of the Platform are as follows:</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="273">
                                        <p><strong>ORDER VALUE</strong></p>
                                    </td>
                                    <td width="280">
                                        <p><strong>DELIVERY CHARGE</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="273">
                                        <p data-renderer-start-pos="366">Rs. 500 or more</p>
                                    </td>
                                    <td width="280">
                                        <p data-renderer-start-pos="393">FREE Shipping</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br></br>
                        <p>Note : Delivery charges quoted above are applicable on ALL orders available on the Platform.</p>
                        <p>2.3 You agree and understand that though we endeavour to ship and deliver our Products all across India, we may, in our sole discretion determine a select list of areas which are unserviceable for delivery of Products. We, or our Logistic Partners, do not provide shipping and delivery services in such unserviceable areas and may not process your orders on the Website in such cases. In the event, an area has been deemed unserviceable by us, we shall notify such user at the time of placing an order for purchase of Products on the Website. You may also verify whether an area is unserviceable for deliveries by us by entering the relevant area pin-code on the Website.</p>
                        <p>2.4 You agree and acknowledge that to effectuate timely delivery of the purchased Products to you we may inquire or collect specific information like your name, shipping address, billing address, landmarks, contact details, etc. You shall ensure that all information that is submitted by you to us on the Website is true, complete, accurate and sufficient to identify the actual place of delivery. You understand that you shall bear absolute liability in case of any failure by us in delivering the purchased Products due to your failure to provide correct, complete, sufficient and accurate information at the time of placing the order. It is further clarified that we shall not be liable in any manner and at any point in time due to your failure to provide correct and complete information.</p>
                        <p>2.5 We will attempt to deliver the purchased Product to your designated address within the estimated timeline of delivery notified to you. In the event you are not available or present to accept the delivery of the Product, our Logistic Partners will make a maximum of three attempts in 3 (three) days (each attempt everyday) to deliver the purchased Product(s) to you. If the third delivery attempt is unsuccessful and you continue to remain unavailable, we reserve the right to cancel the order of the purchased Products at our sole discretion and process the return of such Product to us. We further reserve the right to deduct the shipping and delivery charges borne by us while processing any refunds subsequent to such cancellation.</p>
                        <p>2.6 While we make reasonable endeavours in ensuring that purchased Products are delivered to you in a timely manner and within the timeline notified to you, you accept and acknowledge that the delivery may be delayed on account of:</p>
                        <p>a. Logistical issues beyond our control;</p>
                        <p>b. Unsuitable weather conditions;</p>
                        <p>c. Political disruptions, strikes, employee-lockouts, governmental restrictions, etc;</p>
                        <p>d. Acts of God such as floods, earthquakes, etc;</p>
                        <p>In such events of delay, we shall make reasonable attempt to inform you by writing to your email ID and/or mobile number registered with us. We disclaim all liabilities that may arise on account of our failure to inform or notify you of delays in the delivery of purchased Products on the Website. Further, we shall be under no obligation to compensate you for any claim that may otherwise arise on account of delay in the shipment or delivery or use of the purchased Products.</p>
                        <p>2.7 We endeavour to engage Logistic Partners, employees, agents with the highest regard for ethics and integrity; and behave in a fashion that exudes thorough professionalism, competence and good mannerism. You agree and acknowledge that the actions, inactions of delivery individuals are not in our control, and it is not possible for us to monitor and observe each delivery executive. Since we are merely facilitating delivery of a Product purchased by you, we shall not be liable for any acts or omissions on part of our delivery agents, employees, or personnel and/ or the Logistic Partner or their employees, agents, or personnel including deficiency in service, wrong delivery of Product, time taken to deliver the Product, Product package tampering, etc. For the sake of abundant clarity, it is stated that any ill-mannerism, impoliteness, discourtesy or offensiveness shown by our delivery executives or the employees, agents, personnel of the Logistic Partners is beyond our control and any issue arising between you and our delivery executive or an employee, agent, personnel of the Logistic Partner will have to be resolved by you independently. You agree and acknowledge that you will not hold us responsible or require us to settle, mediate or resolve any disputes between you and the delivery personnel delivering the Products to you.</p>
                        <p>2.8 Once you place an order on the Website, we process such order and hand over the purchased Product to our Logistic Partner. The User will receive a unique tracking identity number once the purchased Product is handed over to the Logistics Partner, which will enable the User in tracking the status of delivery of the purchased Products. The User may use the tracking identity number on the Website or on the website and/or the mobile application of the Logistic Partner to check the status and location of the purchased Product and its estimated time of delivery. Our customer service team coordinates with the Logistic Partners to ensure that the Products are delivered to you at the earliest and make all reasonable efforts in ensuring that the Logistic Partners update the tracking status of the purchased Products on a real time basis. It is clarified that we engage third party service providers to effectuate deliveries of the Products and hence, we do not guarantee the accuracy or correctness of the tracking status and the status may be subject to inconsistencies arising out of time-lags in updating the information and/ or other technical difficulties which are not in our control.</p>
                        <p>2.9 We reserve the right to charge or collect shipping fees on Products from time to time. Shipping charges may vary based on the value of the Product, type of Product, area of delivery, payment mechanism, etc. You agree that, we are authorized to collect, on behalf of the Logistic Partner, the shipping and delivery fees for the delivery service provided by the Logistic Partner. In the event we charge a shipping fees for the delivery of a purchased Product, such shipping fees will not be refunded by us pursuant to any return request raised by you. However, we may make exceptions and refund the shipping fees in the event a defected, damaged, deficient or incorrect Product (for reasons attributable to, and accepted by us after due verification in our sole discretion) has been delivered. You acknowledge and accept that the title and risk of all Products ordered by you shall pass on to you upon the delivery of the purchased Products to you.</p>
                        <p>2.10 Return of purchased Products are facilitated through our reverse-Logistics Partners. On receipt of request for return of Product on the Website and the same being duly acknowledged by us, our reverse-Logistics Partners shall get in touch with you in order to collect the purchased Products from you. We process returns and exchanges of purchased Products in accordance with our Cancellation, Return and Refund Policy.</p>
                        <ol start="3">
                            <li><strong> CUSTOMER SUPPORT</strong></li>
                        </ol>
                        <p>You may direct any queries or concerns relating to the shipping and delivery of Products as per this Policy to our customer support team who can be contacted at the below mentioned details:</p>
                        <p><strong>Contact Details</strong>:</p>
                        <p>E- mail id -acehealthcare2023@gmail.com</p>
                        <p>Contact No - +9056813248</p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
