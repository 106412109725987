// Login.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MDBContainer, MDBCol, MDBRow, MDBBtn, MDBIcon, MDBInput, MDBCheckbox } from 'mdb-react-ui-kit';
import { LoginApi } from '../services/apis/Api';
const Login = () => {
  const [Username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);

  const toggleCheckbox = () => setChecked(!checked);

  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      const result = await LoginApi(Username, password)
      alert(result.message)
      if (result.status === true) {
        localStorage.setItem('acekey', JSON.stringify(result.data));
        navigate("/layout");
      }
    } catch (error) {
      console.log('handleLogin' + error)
    }
  };
  return (
    <MDBContainer fluid className="p-3 my-5 h-custom">
      <MDBRow>
       <MDBCol col='10' md='6' className='Top-img-container'>
          <img src="../../images/no-connection.png" className="img-fluid" alt="Sample image" />
        </MDBCol>

        <MDBCol col='4' md='4'>
          <img src="../../images/act-care.png" className="img-fluid" alt="Sample image" style={{ width: 180 }} />
          <div className="divider d-flex align-items-center my-4">
            {/* <p className="text-center fw-bold mx-3 mb-0">Or</p> */}
          </div>
          <div className="d-flex flex-row align-items-center " style={{marginBottom:10}}>
            <p className="lead fw-normal mb-0 me-3" >Login</p>
          </div>

         

          <MDBInput onChange={(e) => setUsername(e.target.value)} wrapperClass='mb-4' label='Username'  id='formControlLg' type='text' size="lg" />
          <MDBInput onChange={(e) => setPassword(e.target.value)} wrapperClass='mb-4' label='Password' id='formControlLg' type='password' size="lg" />

          <div className="d-flex justify-content-between mb-4">
            {/* <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' color='#4a8ee6' /> */}
            {/* <a href="!#">Forgot password?</a> */}
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:5}}>
          <input
              type="checkbox"
              checked={checked}
              onChange={toggleCheckbox}
            /> 
            <div>I agree to the <span className="link-text"><Link to='https://www.acehealthcare.co.in/terms-and-conditions'>Terms & Conditions</Link> and <Link >Privacy Policy</Link></span></div>
          </div>
          <div className='text-center text-md-start mt-4 pt-2'>
            <MDBBtn disabled={!checked} className="px-5" size='lg' onClick={handleLogin} style={{ backgroundColor: '#4a8ee6' }} >Login</MDBBtn>
            {/* <p className="small fw-bold mt-2 pt-1 mb-2">Don't have an account? <a href="#!" className="link-danger">Register</a></p> */}
          </div>
        </MDBCol>
      </MDBRow>

      <div className="d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5 " style={{ backgroundColor: '#4a8ee6', position: 'absolute', width: '98%', bottom: 0 }}>
        <div className="text-white mb-3 mb-md-0" >
          Copyright © 2020. All rights reserved.
        </div>

        <div>

          <MDBBtn tag='a' color='none' className='mx-3' style={{ color: 'white' }}>
            <MDBIcon fab icon='facebook-f' size="md" />
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='mx-3' style={{ color: 'white' }}>
            <MDBIcon fab icon='twitter' size="md" />
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='mx-3' style={{ color: 'white' }}>
            <MDBIcon fab icon='google' size="md" />
          </MDBBtn>

          <MDBBtn tag='a' color='none' className='mx-3' style={{ color: 'white' }}>
            <MDBIcon fab icon='linkedin-in' size="md" />
          </MDBBtn>

        </div>

      </div>

    </MDBContainer>
  );
};

export default Login;
