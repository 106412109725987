// SignUp.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignUp = () => {
    // Handle sign up logic here
    console.log("Signed up with:", email, password);
  };

  return (
    <div className="container" style={{ backgroundColor: "#f7fbff" ,textAlign:'center',width:500}}>
      <h2>Sign Up</h2>
      <div style={{margin:10}}>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      </div>
       <div style={{margin:10}}>
       <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
       </div>
      <p>
        Already have an account? <Link to="/login">Log in</Link>
      </p>
    </div>
  );
};

export default SignUp;
