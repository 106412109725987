import React, { useEffect, useState } from 'react';
import CommonHeader from '../common/CommonHeader';
import { CategoryDetails_API, ProductDetails_API } from '../services/apis/Api';
import { useParams } from 'react-router-dom';
import { Footer } from '../templates/Footer';

const ProductDetails = () => {
  const [ProductData, setProductData] = useState('')
  const [selectedOption, setSelectedOption] = useState('');
  const { Productname } = useParams();
  // console.log(Productname)
  const IdBeforeSlash = Productname.split("/")[0];
  // console.log(IdBeforeSlash);
  const Get_product_details = async () => {
    const result = await ProductDetails_API(IdBeforeSlash);
    console.log(result)
    setProductData(result.data[0])
  }

  const generateMultiples = (value, limit) => {
    const multiples = [];
    for (let i = 1; i <= limit; i++) {
      multiples.push({ label: `${value * i}`, value: `${value * i}`, });
    }
    return multiples;
  };
  
  const DropdownItems = generateMultiples(5, 10);
  
  const updateQuantity = (itemId, event) => {
    // console.log(itemId,event.target.value)
    const value = event.target.value
    // setSelectedOption(value)
    // dispatch(updateCartItemQuantity(itemId, value));
  };


  console.log(ProductData)
  useEffect(() => {
    Get_product_details();
  }, [])

  return (
    <div>
      <CommonHeader name="Product Details" />
      {
        ProductData ?
          <div className="product-details-container">
            <div className="product-info-container" style={{display:'flex',justifyContent:'center' ,gap:20}}>
              <div>
             <img src={ProductData.product_image} alt='img' className="product-image" />
          
              </div>
              <div >
              <h2 style={{fontWeight:'bold'}}>{ProductData.pruduct_name}</h2>
              <label style={{ color: '#4a8ee6', }}>Price</label>
              <div style={{ display: 'flex', gap: 5 }}><strong style={{ fontSize: 22, marginRight: 6 }}>{ProductData.user_price}</strong><p style={{ textDecoration: 'line-through' }}>{ProductData.mrp}</p></div>
              <p><strong>Batch No:</strong> {ProductData.batch_no}</p>
              <p><strong>Expiry Date:</strong> {ProductData.batch_expiry}</p>
              <p><strong>Category Name:</strong> {ProductData.category_name}</p>
              </div>
              <div style={{marginTop:'auto'}}>
              <div className='Selectquantity'>
                <label htmlFor="dropdown" >Select quantity:</label>
                <div className='Dropdown_input_Box'>
                  <input
                    // type="number"
                    className="quantity-input"
                    style={{ height: '100%', border: 0, width: 33, paddingLeft: 4 }}
                    value={ProductData.quantity}
                    // onChange={(value) => { updateQuantity(product.id, value) }}
                  />
                  <select
                    id="dropdown"
                    value={selectedOption}
                    // onChange={(value) => { updateQuantity(product.id, value) }}
                    style={{ width: 20, border: 0 }}
                  >
                    {DropdownItems.map((item) => (
                      <option key={item.value} >{item.value}</option>
                    ))}

                  </select>
                </div>
              </div>
              <button className="add-to-cart-button" style={{ backgroundColor: '#4a8ee6', width:200}}>Add to Cart</button>
              </div>
            </div>
          </div> : null
      }
      <Footer />
    </div>
  );
}

export default ProductDetails;
