import { ADD_TO_CART, REMOVE_FROM_CART, UPDATE_CART_ITEM_QUANTITY } from './cartActionTypes';

export const addToCart = (item) => ({
  type: ADD_TO_CART,
  payload: item
});

export const removeFromCart = (itemId) => ({
  type: REMOVE_FROM_CART,
  payload: itemId
});

export const updateCartItemQuantity = (itemId, newQuantity) => ({
  type: UPDATE_CART_ITEM_QUANTITY,
  payload: { itemId, newQuantity }
});
