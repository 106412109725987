import React, { useEffect, useState } from 'react';
import actcare from '../../images/act-care.png';
import playstore from '../../images/play_store.png';
import appstore from '../../images/app_store.png'
import '../../style/Style.css';
import '../../style/media.css'
import { Link } from 'react-router-dom';
import { HomeDataApi } from '../services/apis/Api';
export const Footer = () => {
   const [data, setdata] = useState('');
   const itemsPerColumn = 7;

   // Split the data into chunks of itemsPerColumn
   const categoryChunks = data ?
      Array.from({ length: Math.ceil(data.data.home_category.length / itemsPerColumn) }, (v, i) =>
         data.data.home_category.slice(i * itemsPerColumn, i * itemsPerColumn + itemsPerColumn)
      ) : [];
     

      const handleHomedataApi = async () => {
        try {
            const result = await HomeDataApi(); 
            setdata(result)     
        } catch (error) {
            console.log('handleHomedataApi' + error)
        }
    };
    useEffect(() => {
      handleHomedataApi();
    }, []);
   return (
      <div>
         <footer>
            <div className="footer-top">
               <div className="footer-logo">
                  <img src={actcare} />
               </div>
               {/* <div className="footer-text">
                  Acehealthcare.co.in is one of India’s most trusted pharmacies,
                  dispensing quality medicines at reasonable prices to over
                  7 million happy customers – PAN India.
               </div> */}
            </div>
            <div className="footer-veiw">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 col">
                        <ul>
                           <li>
                              <h2 style={{color:'#4a8ee6'}}>Company</h2>
                           </li>
                           <li><a href="/about-us">About us</a></li>
                           <li><a href="/contact-us">Contact us</a></li>
                           {/* <li><a href="">Customers Speak</a></li> */}
                           {/* <li><a href="/news">News</a></li> */}
                           {/* <li id="netmeds_career"><a href="" target="_blank">Career</a></li> */}
                           <li><a href="/terms-conditions">Terms and Conditions</a></li>
                           <li><a href="/privacy-policy">Privacy Policy</a></li>
                           <li><a href="/payment-policy">Payments Policy</a></li>
                           <li><a href="/shipping-delivery-policy">Shipping and Delivery Policy</a></li>
                           <li><a href="/cancellation-refund-policy">Cancellation and Refund Policy</a></li>
                           {/* <li><a className="contact-link" href="/Contactus">Contact</a></li> */}
                        </ul>
                     </div>
                     {/* <div className="col-md-2 col">
                        <ul>
                           <li>
                              <h2>Shopping</h2>
                           </li>
                           <li><a href="">Browse by A-Z</a></li>
                           <li><a href="">Browse by Manufacturers</a></li>
                           <li><a href="">Health Articles</a></li>
                           <li className="sbilabhide"><a href="">Offers / Coupons</a></li>
                           <li><a href="">FAQs</a></li>
                        </ul>
                     </div> */}
                     {/* <div className="col-md-2 col">
                        <ul>
                           <li>
                              <h2>CATEGORIES</h2>
                           </li>
                           {
                              data ?
                                 data.data.home_category.map((item, index) => (
                                    <li key={index}><Link to={`/CategoryDetails/${encodeURIComponent(item.id + '/' + item.category_name)}`}>{item.category_name}</Link></li>
                                 )) : null
                           }
                         
                        </ul>
                     </div> */}
                     <div  className="col-md-4 col">
                     <div className="row">
                        {categoryChunks.map((chunk, colIndex) => (
                           <div className="col-md-5 col" key={colIndex}>
                              <ul>
                                 {colIndex === 0 && (
                                    <li>
                                       <h2 style={{color:'#4a8ee6'}}>CATEGORIES</h2>
                                    </li>
                                 )}
                                 {chunk.map((item, index) => (
                                    <li key={index}>
                                       <Link to={`/CategoryDetails/${encodeURIComponent(item.id + '/' + item.category_name)}`}>
                                          {item.category_name}
                                       </Link>
                                    </li>
                                 ))}
                              </ul>
                           </div>
                        ))}
                     </div>
                     </div>
                     {/* <div className="col-md-1 col">
                        <ul>
                           <li>
                              <h2>Social</h2>
                           </li>
                           <li><a href="" target="_blank">Patients Alike</a></li>
                           <li><a href="" target="_blank">Facebook</a></li>
                           <li><a href="" target="_blank">Twitter</a></li>
                           <li><a href="" target="_blank">LinkedIn</a></li>
                           <li><a href="" target="_blank">Youtube</a></li>
                           <li><a href="">Refer &amp; Earn</a></li>
                        </ul>
                     </div> */}
                     {/* <div className="col-md-3 col">
                        <div className="subscribe">
                           <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
                           Get a free subscription to our health and fitness tip and stay tuned to our latest offers
                           <form id="newsletter-validate-detail" name="" method="post" noValidate="novalidate" >
                              <div className="control"> <input name="newsletter_email" type="email" maxLength={50} className="Required-newsletter-email validate-newsletter-emailid" id="newsletter_email" placeholder="Enter your email address" /> <button className="go-btn" title="Go" type="submit"><i className="fa-solid fa-arrow-right"></i></button> </div>
                              <div className="newsletter_error"></div>
                              <div className="loaded"></div>
                              <div className="load-out" style={{ display: 'flex' }}></div>
                           </form>
                           <div className="clearfix"></div>
                           <div className="app-store"> <a className="store-icon" href="" rel="noopener" title="Download Acehealthcare App for Android from Play Store" target="_blank"> <img src={playstore} alt="Download Acehealthcare App for iOs from App Store" title="Download Acehealthcare App for iOs from App Store" /> </a>
                              <a className="store-icon" href="" title="Download Acehealthcare App for iOs from App Store" rel="noopener" target="_blank"> <img src={appstore} alt="Download Acehealthcare App for iOs from App Store" title="Download Acehealthcare App for iOs from App Store" /> </a> </div>
                           <div className="clearfix"></div>
                        </div>
                     </div> */}

                  </div>
               </div>
            </div>
         </footer>
         <small className="copyright">
            <div className="copyblock">
               <div className="footer_link"><a href="">Best Quality</a></div>
               <div className="footer_link"><a href="">Economical Rates</a></div>
               {/* <div className="footer_link sbilabhide"><a href="">Lab Tests</a></div> */}
               <div className="footer_link"><a href="">Timely Delivery</a></div>
            </div>
         </small>
      </div>
   )
}
