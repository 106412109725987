const HomedataURL = 'https://ace.techonist.com/api/home_slider?party_id=4';
 const getUserId = async () => {
  try {
    const storedData = await localStorage.getItem('acekey');
    const convertdata = JSON.parse(storedData)
    const Id = convertdata.party_id;
    return Id
  } catch (error) {
    console.error('Error getting userId from localStorage:', error);
    throw error;
  }
};
export const HomeDataApi = async () => {
  try {
    const response = await fetch(`${HomedataURL}`);
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};
export const LoginApi = async (mobile, password) => {
  try {
    const response = await fetch(`https://ace.techonist.com/api/user_login?mobile=${mobile}&password=${password}&token_id=asdfasdfafsfasdf&model_number=samsung30`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonData = await response.json();
    //   console.log(jsonData)
    // Save data to localStorage only if response status is true
    return jsonData
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
export const OffersAPI = async () => {
  try {
    const Id = (await getUserId());
    const response = await fetch(`https://ace.techonist.com/api/offers_list?party_id=${Id}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};
export const CouponCodeAPI = async (code) => {
  try {
    const Id = (await getUserId());
    const response = await fetch(`https://ace.techonist.com/api/check_promo_code?party_id=${Id}&promo_code=${code}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};
export const Add_order_API = async (orderData) => {
  try {
    const Id = (await getUserId());
    const response = await fetch('https://ace.techonist.com/api/add_order?party_id=' + Id + '&order_by=party&order_info=' + JSON.stringify(orderData), {
      headers: {
        'Accept': 'application/json',
        'Contyent-Tpe': 'application/json'
      },
    });
    const data = await response.json();
    return data
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};
export const ProductListAPI = async () => {
  try {
    // const Id = (await getUserId());
    const response = await fetch(`https://ace.techonist.com/api/product_list?party_id=948`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};

export const OrderAPI = async () => {
  try {
    const Id = (await getUserId());
    const response = await fetch(`https://ace.techonist.com/api/party_orders?party_id=${Id}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};

export const party_invoicesApi = async () => {
  try {
    // const Id = (await getUserId());
    const response = await fetch(`https://ace.techonist.com/api/party_invoices?party_id=1108`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};

export const ProductDetails_API = async (id) => {
  try {
    // const Id = (await getUserId());
    console.log(id)
    const response = await fetch(`https://ace.techonist.com/api/product_detail?party_id=948&product_id=${id}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};

export const CategoryDetails_API = async (id) => {
  try {
    // const Id = (await getUserId());
    const response = await fetch(`https://ace.techonist.com/api/product_list_by_category?party_id=948&category_id=${id}`);
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('Error fetching data:', error);
  }
};