import React, { useEffect, useState } from 'react'
import '../../style/Style.css';
import logo from '../../images/act-care.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Image, InputGroup, Modal, Offcanvas, Row, Container, Button } from 'react-bootstrap';
import { HomeDataApi, ProductListAPI } from '../services/apis/Api';
import { addToCart } from '../../Redux/cartActions';

export const Header = () => {
   const [searchInput, setSearchInput] = useState('');
   const [Data, setData] = useState('');
   const [lgShow, setlgShow] = useState(false);
   const [ProductListData, setProductListData] = useState([])
   const [FilterData, setFilterData] = useState([])
   const [show, setShow] = useState(false);
   const [data, setdata] = useState('');

  const handleHomedataApi = async () => {
    try {
        const result = await HomeDataApi(); 
        setdata(result)     
    } catch (error) {
        console.log('handleHomedataApi' + error)
    }
};


   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const navigate = useNavigate();
   const cart = useSelector(state => state.cart.items);
   const dispatch = useDispatch();

   const getLocalStorageData = async () => {
      try {
         const storedData = await localStorage.getItem('acekey');
         if (storedData) {
            setData(JSON.parse(storedData));
         } else {
            setData('')
         }
      } catch (error) {
         console.log('getLocalStorageData' + error)
      }
   };
   const getproductListData = async () => {
      try {
         const result = await ProductListAPI();
         setProductListData(result.data)
         setFilterData(result.data)
      } catch (error) {
         console.log('getproductListData' + error)
      }
   };
   const onSearch = (text) => {
      if (FilterData) {
         if (text) {
            let tempList = ProductListData.filter((item) => {
               return item.pruduct_name.toLowerCase().indexOf(text.toLowerCase()) > -1;
            });
            setFilterData(tempList);
         } else {
            setFilterData(ProductListData)
         }
      } else {
         alert('There is no data')
      }
   };

   useEffect(() => {
      getLocalStorageData();
      getproductListData();
   }, [])
   useEffect(() => {
      handleHomedataApi();
    }, []);
   // console.log(Data)

   return (
      <>
         <section className='main_section_web'>
            <div className="page-header">
               <div className="panel-wrapper">
                  <div className="panel-header">
                     <div className="logo"> <a href="/"> <img src={logo} alt="Netmeds.com, India ki Online Pharmacy" />
                     </a> </div>

                     {/* mobile responsive Div Start   */}
                     <div className="nav_bar-detail-icons">
                        <div className="notification-media">
                           <i className="fa-solid fa-bell"></i>
                           <span className="offer-count">
                              <p>0</p>
                           </span>
                        </div>
                        <div className="cart-media">
                           <i className="fa-solid fa-cart-shopping m-res"></i>
                           <span className="offer-count-cart">
                              <p>{cart.length}</p>
                           </span>
                        </div>
                     </div>
                     {/* mobile responsive Div End   */}


                     <div className="search-bar ">
                        <div className="block-search">
                           {/* <div className="delivery_popup" style={{ display: 'none' }}>
                              <div className="overlay_bg"></div>
                              <div className="pinoce_content">
                                 <div id="saved_location">
                                    <h1 className="title">Where do you want the delivery?</h1>
                                    <div className="clearfix"></div>
                                    <span id="delivery_txt">Get access to your Addresses, Orders, and Wishlist</span>
                                    <div className="clearfix"></div>
                                    <button type="button" className="signin_btn">Sign in to see your location</button>
                                    <div className="saved_location_swiper swiper-container">
                                       <div className="swiper-wrapper" id="saved_addresses"> Loading.. </div>
                                    </div>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div id="delivery_details">
                                    <h1>Or Enter Pincode</h1>
                                    <span>Select pincode to see product availability.</span>
                                    <div className="clearfix"></div>
                                    <div className="pCode_form">
                                       <div id="rel_pincode_form">
                                          <div className="pin_detail">
                                             <input type="tel" className="inp_text" name="rel_pincode" id="rel_pincode"
                                                placeholder="Enter Pincode" maxLength={6} />
                                             <div className="clearfix"></div>
                                          </div>
                                          <div className="clearfix"></div>
                                          <div id="rel_pin_msg"></div>
                                          <div className="clearfix"></div>
                                       </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="detect_location">Detect my location</div>
                                    <div className="clearfix"></div>
                                 </div>
                                 <div className="clearfix"></div>
                              </div>
                           </div> */}
                           <div className="auto-search">
                              <form id="search_form" action="" method="GET">
                                 <div className="search-section">
                                    <div className="algolia-autocomplete"
                                       style={{ direction: 'ltr', justifyContent: 'center', overflow: 'hidden' }}>
                                       <div style={{ fontSize: 15, marginTop: 15, marginLeft: 13, overflow: 'hidden' }} onClick={() => { setlgShow(true) }}>Search for medicine &amp; wellness products…</div>
                                       <Modal
                                          size="lg"
                                          show={lgShow}
                                          onHide={() => setlgShow(false)}
                                          aria-labelledby="example-modal-sizes-title-lg"
                                       >
                                          <Modal.Header closeButton>
                                             <InputGroup>
                                                <InputGroup.Text id="basic-addon1">Search</InputGroup.Text>
                                                <Form.Control
                                                   placeholder="Search for medicine &amp; wellness products…"
                                                   aria-label="Username"
                                                   aria-describedby="basic-addon1"
                                                   onChange={(e) => { onSearch(e.target.value) }}
                                                />
                                             </InputGroup>
                                          </Modal.Header>
                                          <Modal.Body>
                                             {
                                                FilterData.map((item) => (
                                                   <div key={item.id} className='search_border'>
                                                      <Row>
                                                         <Col xs={6} md={4}>
                                                            <Image width={180} height={130} src={item.product_image} rounded />
                                                         </Col>
                                                         <Col xs={12} md={8}>
                                                            <h5>{item.pruduct_name}</h5>
                                                            <h6>{item.user_price}</h6>
                                                            <p>Akuments Heathcare</p>
                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                               <div></div>
                                                               <button onClick={() => { dispatch(addToCart(item)); }} className="place-order-button" >Add to Cart</button>
                                                            </div>
                                                         </Col>
                                                      </Row>
                                                   </div>
                                                ))
                                             }
                                          </Modal.Body>
                                       </Modal>

                                       <pre aria-hidden="true"
                                          style={{ whiteSpace: 'pre', visibility: 'hidden', fontFamily: 'lato', fontSize: 14, fontStyle: 'normal', fontVariant: 'normal', fontWeight: '400', wordSpacing: 0, letterSpacing: 'normal', textIndent: 0, textRendering: 'auto', textTransform: 'none' }}></pre>
                                       <div className="aa-dropdown-menu" role="listbox" id="algolia-autocomplete-listbox-0"
                                          style={{ position: 'absolute', top: '100%', zIndex: '100', display: 'none', left: 0, right: 'auto' }}>
                                          <div className="aa-dataset-1"></div>
                                       </div>
                                    </div>

                                 </div>
                                 {/* <!-- <button className="iconSearch" type="submit"></button> --> */}
                              </form>
                           </div>
                        </div>
                     </div>
                     {/* offcanvas code */}
                     <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                           <Offcanvas.Title>Profile</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                           <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <div className="border border-dark rounded-circle" style={{ width: '150px', height: '150px' }}>
                                 <img src="../../images/act-logo.png" className="w-100 h-100 rounded-circle" />
                              </div>
                           </div>

                           {/* Step 2: Username and Email */}
                           <div className="text-center mb-4">
                              <h3>{Data ? Data.name : null}</h3>
                              <p>{Data ? Data.email : null}</p>
                           </div>

                           {/* Step 3: Edit Profile Button */}
                           <div className="text-center">
                              <Button variant="primary" onClick={handleClose}>Edit Profile</Button>
                           </div>
                        </Offcanvas.Body>
                     </Offcanvas>
                     <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '19%', gap: 10 }}>
                        <div data-block="minicart" className="mini-cart" id="minicart_btn">
                           <div className="text"> <i className="fa-solid fa-cart-shopping" onClick={() => { navigate("/Cart"); }}></i>{cart.length > 0 ? <span className="counter-number">{cart.length}</span> : null}
                              Cart </div>
                           <div className="minicart_container">
                              <div className="mc_triangle"></div>
                              <div className="mc_content"></div>
                           </div>
                        </div>
                        <div className="login">
                           <div>
                              {Data ?
                                 <div className="dropdown medicin">
                                    <button className="dropbtn"><i className="fa-solid fa-user"></i>
                                       <span> {Data.name} </span> <i className="fa-solid fa-caret-down"></i></button>
                                    <div className="dropdown-content">
                                       <a href='#' onClick={handleShow}>Profile</a>
                                       <a href='#' onClick={() => { navigate('/Order') }}>Orders</a>
                                       <a href='#' onClick={() => { navigate('/Payment') }}>Payment</a>
                                       <a href='' onClick={() => { localStorage.removeItem('acekey') }}>Logout</a>
                                    </div>
                                 </div>
                                 :
                                 <div className="logged">
                                    <a href="/Login" style={{ color: '#fff', fontSize: 16, fontWeight: '500' }}> <i className="fa-solid fa-user"></i> Sign in / Sign up </a>
                                 </div>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

            </div>
            <div className="product-menu">
               <div className="container">
                  <div className="row">
                     <div className="col-md-3 col">
                        <div className="dropdown medicin">
                           <button className="dropbtn">
                              <i className="fa-solid fa-prescription-bottle-medical"></i>
                              <span> Allopathic Medicine</span> <i className="fa-solid fa-caret-down"></i></button>
                           <div className="dropdown-content">
                              {/* <a href="#">All Medicines</a> */}
                              {/* <a href="#">Previously Ordered Products</a> */}
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3 col" style={{ position: 'unset' }}>
                        <div className="dropdown medicin">
                           <button className="dropbtn"><i className="fa-solid fa-spa"></i> <span> Ayurvedic Medicine </span> <i
                              className="fa-solid fa-caret-down"></i></button>
                           <div className="dropdown-content">
                              {/* <a href="#">All Medicines</a> */}
                              {/* <a href="#">Previously Ordered Products</a> */}
                           </div>
                        </div>
                     </div>
                     <div className="col-md-3 col" style={{ position: 'unset' }}>
                        <div className="product-menu-div">
                           <i className="fa-solid fa-vial" style={{width:60}}></i> <span> Dietary Supplement </span>
                        </div>
                     </div>
                     <div className="col-md-3 col">
                        <div className="dropdown medicin">
                           <button className="dropbtn"><i className="fa-solid fa-spray-can-sparkles"></i> <span> Veterinary Supplement </span> <i
                              className="fa-solid fa-caret-down"></i></button>
                           <div className="dropdown-content">
                              {/* <a href="#">Personal Care</a> */}
                              {/* <a href="#">Make-Up</a> */}
                              {/* <a href="#">Hair</a> */}
                              {/* <a href="#">Fragrances</a> */}
                              {/* <a href="#">Men's Grooming</a> */}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="nav-sections">
               <nav className="navigation">
                  <ul className="ui-menu" >
                     {
                        data ?
                           data.data.home_category.map((item, index) => (
                              <li key={index}><Link to={`/CategoryDetails/${encodeURIComponent(item.id + '/' + item.category_name)}`} className="level-top" data-eventkey="Ace-covid-essentials" href="">{item.category_name}</Link></li>
                           )) : null
                     }
                  </ul>
               </nav>
            </div>
         </section>
         {/* <!-- nav-sections product itmes SHow --> */}




         {/* <!-- only for responsive Div Start  --> */}


         {/* <!-- responsive Div Start  --> */}

         <div className="media-responsive-div">
            <div className="container">
               <div className="row">
                  <div className="col-md-12 col">

                     <div className="search-bar m-veiw">
                        <div className="block-search">
                           {/* <div className="pinCode" id="location_box">
                              <div className="accordion" id="accordionExample">
                                 <div className="">
                                    <div className="dropdown pin-code-div">
                                       <button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1"
                                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"> Deliver to
                                          <span className="pin_code">110002 </span><span className="caret"></span></button>
                                       <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                                          <li><a href="#" data-toggle="modal" data-target="#modal1">
                                             <div id="saved_location">
                                                <h1 className="title">Where do you want the delivery?</h1>
                                                <div className="clearfix"></div>
                                                <span id="delivery_txt">Get access to your Addresses, Orders, and
                                                   Wishlist</span>
                                                <div className="clearfix"></div>
                                                <button type="button" className="signin_btn">Sign in to see your
                                                   location</button>
                                                <div className="saved_location_swiper swiper-container">
                                                   <div className="swiper-wrapper" id="saved_addresses"> Loading.. </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                <div id="delivery_details">
                                                   <h1>Or Enter Pincode</h1>
                                                   <span>Select pincode to see product availability.</span>
                                                   <div className="clearfix"></div>
                                                   <div className="pCode_form">
                                                      <div id="rel_pincode_form">
                                                         <i className="fa-solid fa-location-dot"></i>
                                                         <div className="pin_detail">
                                                            <input type="tel" className="inp_text" name="rel_pincode"
                                                               id="rel_pincode" placeholder="Enter Pincode"
                                                               maxLength={6} />

                                                            <div className="clearfix"></div>
                                                         </div>
                                                         <div className="clearfix"></div>
                                                         <div id="rel_pin_msg"></div>
                                                         <div className="clearfix"></div>
                                                      </div>
                                                   </div>
                                                   <div className="clearfix"></div>
                                                   <div className="detect_location"><i
                                                      className="fa-solid fa-location-crosshairs"></i> Detect my location
                                                   </div>
                                                   <div className="clearfix"></div>
                                                </div>
                                             </div>
                                          </a></li>
                                       </ul>
                                    </div>

                                 </div>

                              </div>
                           </div> */}
                           {/* <div className="delivery_popup" style={{ display: 'none' }}>
                              <div className="overlay_bg"></div>
                              <div className="pinoce_content">
                                 <div id="saved_location">
                                    <h1 className="title">Where do you want the delivery?</h1>
                                    <div className="clearfix"></div>
                                    <span id="delivery_txt">Get access to your Addresses, Orders, and Wishlist</span>
                                    <div className="clearfix"></div>
                                    <button type="button" className="signin_btn">Sign in to see your location</button>
                                    <div className="saved_location_swiper swiper-container">
                                       <div className="swiper-wrapper" id="saved_addresses"> Loading.. </div>
                                    </div>
                                 </div>
                                 <div className="clearfix"></div>
                                 <div id="delivery_details">
                                    <h1>Or Enter Pincode</h1>
                                    <span>Select pincode to see product availability.</span>
                                    <div className="clearfix"></div>
                                    <div className="pCode_form">
                                       <div id="rel_pincode_form">
                                          <div className="pin_detail">
                                             <input type="tel" className="inp_text" name="rel_pincode" id="rel_pincode"
                                                placeholder="Enter Pincode" maxLength={6} />
                                             <div className="clearfix"></div>
                                          </div>
                                          <div className="clearfix"></div>
                                          <div id="rel_pin_msg"></div>
                                          <div className="clearfix"></div>
                                       </div>
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="detect_location">Detect my location</div>
                                    <div className="clearfix"></div>
                                 </div>
                                 <div className="clearfix"></div>
                              </div>
                           </div> */}
                           <div className="auto-search">
                              <form id="search_form" action="" method="GET">
                                 <div className="search-section">
                                    <div className="algolia-autocomplete"
                                       style={{ position: 'relative', display: 'block', direction: 'ltr' }}>
                                       <input tabIndex={0} id="search" type="text" name="q"
                                          className="input-text algolia-search-input aa-input"
                                          spellCheck="false" autoCorrect="off" autoCapitalize="off"
                                          placeholder="Search for medicine &amp; wellness products…" role="combobox"
                                          aria-autocomplete="list" aria-expanded="false"
                                          aria-owns="algolia-autocomplete-listbox-0" dir="auto"
                                          style={{ position: 'relative', verticalAlign: 'top' }} />
                                       <pre aria-hidden="true"
                                          style={{ position: 'absolute', visibility: 'hidden', whiteSpace: 'pre', fontFamily: 'lato', fontSize: 14, fontStyle: 'normal', fontVariant: 'normal', fontWeight: '400', wordSpacing: 0, letterSpacing: 'normal', textIndent: 0, textRendering: 'auto', textTransform: 'none' }}></pre>
                                       <div className="aa-dropdown-menu" role="listbox" id="algolia-autocomplete-listbox-0"
                                          style={{ position: 'absolute', top: '100%', zIndex: '100', display: 'none', left: 0, right: 'auto' }}>
                                          <div className="aa-dataset-1"></div>
                                       </div>
                                    </div>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className="responsive-product-list">
            <div className="row">
               <div className="col">
                  <a href="">
                     <div className="product-list-img">
                        <img src="<?php  echo BACKEND_THEME_SITE_URL ?>images/promotion-1.png" />
                     </div>
                     <span className="major-content">Medicines</span>
                  </a>

               </div>
               <div className="col">
                  <a href="">
                     <div className="product-list-img">
                        <img src="<?php  echo BACKEND_THEME_SITE_URL ?>images/promotion-2.png" />
                     </div>
                     <span className="major-content">Wellness</span>
                  </a>
               </div>
               <div className="col">
                  <a href="">
                     <div className="product-list-img">
                        <img src="<?php  echo BACKEND_THEME_SITE_URL ?>images/promotion-3.png" />
                     </div>
                     <span className="major-content">Lab Tests</span>
                  </a>

               </div>
               <div className="col">
                  <a href="">
                     <div className="product-list-img">
                        <img src="<?php  echo BACKEND_THEME_SITE_URL ?>images/promotion-4.png" />
                     </div>
                     <span className="major-content">Consult Doctor</span>
                  </a>

               </div>
            </div>

         </div>
      </>
   )
}





