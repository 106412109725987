import React, { useEffect, useState } from 'react'
import { Container, Tab, Tabs, Row, Col, Button, Modal } from 'react-bootstrap'
import CommonHeader from '../common/CommonHeader'
import { party_invoicesApi } from '../services/apis/Api';
import DueInvoice from './DueInvoice';
import { UpcomingInvoice } from './UpcomingInvoice';
import { PaidInvoice } from './PaidInvoice';


const Payment = () => {
    const [due_invoices, setdue_invoices] = useState([]);
    const [upcoming_invoices, setupcoming_invoices] = useState([]);
    const [Paid_invoices, setPaid_invoices] = useState([]);
    const [isLoading, setisLoading] = useState(true)

 
    useEffect(() => {
        HandaleInvoiceApi();
    }, []);

    const HandaleInvoiceApi = async () => {
        try {
            const result = await party_invoicesApi();
            setdue_invoices(result.data.due_invoices);
            setupcoming_invoices(result.data.upcoming_invoices)
            setPaid_invoices(result.data.paid_invoics);
        } catch (error) {
            console.log('HandaleInvoiceApi' + error);
        }finally{
            setisLoading(false);
        }
    };

  
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <CommonHeader name="Payment" />
            <Container>
                <Tabs
                    defaultActiveKey="home"
                // id="fill-tab-example"
                // className="custom-tab"             
                >
                    <Tab eventKey="home" title="Due Now" >
                       <DueInvoice due_invoices={due_invoices}/>
                    </Tab>
                    <Tab eventKey="profile" title="Up Coming">
                        <UpcomingInvoice upcoming_invoices={upcoming_invoices}/>
                    </Tab>
                    <Tab eventKey="longer-tab" title="Paid Invoices">
                       <PaidInvoice paid_invoices={Paid_invoices}/>
                    </Tab>
                </Tabs>
            </Container>


        </div>
    )
}

export default Payment