import React, { useState } from 'react';
import { Container, Row, Col, Button,} from 'react-bootstrap'

export const UpcomingInvoice = (data) => {
  const upcoming_invoices = data.upcoming_invoices
  const [checkedItems, setCheckedItems] = useState(upcoming_invoices && upcoming_invoices.length > 0 ? upcoming_invoices.map(item => item.invoice_id) : []);

  const toggleCheckbox = (item) => {
    const isChecked = checkedItems.includes(item.invoice_id);
    if (isChecked) {
      setCheckedItems(checkedItems.filter((id) => id !== item.invoice_id));
    } else {
      setCheckedItems([...checkedItems, item.invoice_id]);
    }
  };

  const getTotal = () => {
    let total = 0;
    let invoiceIds = ''; // String to store invoice_ids
    if (upcoming_invoices && upcoming_invoices.length > 0) {
      upcoming_invoices.forEach((item) => {
        if (checkedItems.includes(item.invoice_id)) {
          total += parseFloat(item.total_amount);
          invoiceIds += item.invoice_id + '-'; // Concatenate invoice_id with a separator
        }
      });
    }
    return { total, invoiceIds }; // Return total and invoiceIds
  };

  let result = getTotal();
  let Totalamount = result.total;
  let invoiceIdsString = result.invoiceIds; // String containing invoice_ids

  
  if (invoiceIdsString.endsWith('-')) {
    invoiceIdsString = invoiceIdsString.slice(0, -1);
  }
    return (
        <div>
            {upcoming_invoices.status !== false ?
                <div>
                    {upcoming_invoices.map((item) => (
                        <Container style={{ backgroundColor: '#f8f9fa', padding: '20px', marginTop: 10, borderRadius: '10px' }} key={item.invoice_id}>
                            <Row className="mb-4">
                                <Col className="d-flex align-items-center">
                                    <input type="checkbox" checked={checkedItems.includes(item.invoice_id)} onClick={() => toggleCheckbox(item)}  id="checkbox"  name="checkbox" />
                                    <h6 style={{ marginLeft: 6 }}>{item.invoice_no}</h6>
                                </Col>
                                <Col className="d-flex justify-content-end align-items-center">
                                    <h6 className="mb-0">Amount: Rs  {item.total_amount}</h6>
                                </Col>
                            </Row>
                            <div>
                                <h6>Due Date:  {item.invoice_date}</h6>
                                <h6>Order Date: {item.due_date}</h6>
                            </div>

                            <Row className="mt-4 justify-content-end">
                                <Button variant="primary" href={item.invoice_url} style={{ width: 'auto', backgroundColor: '#8fd736' }} >Show Details</Button>
                            </Row>
                        </Container>
                    ))}
                    <div style={{ position: 'fixed', bottom: '0px', left: '20px', right: '20px', backgroundColor: '#fff', padding: '10px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p>Total Amount:  Rs {Totalamount}</p>
                        <Button variant="success" href={`https://acehealthcare.co.in/pg/pg.php?order_id=${invoiceIdsString}&total_amount=${Totalamount}`}>Make Payment</Button>
                    </div>
                </div> : <div style={{textAlign:'center'}}>There are no record</div>
            }
        </div>
    )
}
