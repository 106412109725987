import React, { useState, useEffect } from 'react'
import '../../style/Style.css';
import '../../style/media.css';
import { Carousel } from 'react-bootstrap';
import popupbanner from '../../images/popup_banner.jpg';
import banner from '../../images/banner.jpg';
import Wellnessnew from '../../images/Wellnessnew.svg';
import Beautynew from '../../images/Beautynew.svg';
import minibannerweb from '../../images/mini-banner_web.jpg'
import vicksimg from '../../images/vicks_3_in_1_cough_nose_throat_lozenges_orange_menthol_8s_0_0.jpg'
import 'bootstrap/dist/css/bootstrap.min.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { HomeDataApi } from '../services/apis/Api';
import product1 from '../../images/product_img1.png';
import mini_web from '../../images/Mini_Web.jpg'
import { addToCart } from '../../Redux/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { MDBMultiCarousel, MDBMultiCarouselItem } from 'mdb-react-multi-carousel';
import { Button } from 'react-bootstrap';

export const Home = ({ Data }) => {
    //  console.log(Data)
    const [index, setIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');

    const dispatch = useDispatch();

    const handleAddToCart = async (item, event) => {
        event.preventDefault(); // Prevents default form submission behavior
        dispatch(addToCart(item));

    };

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };


    const generateMultiples = (value, limit) => {
        const multiples = [];
        for (let i = 1; i <= limit; i++) {
            multiples.push({ label: `${value * i}`, value: `${value * i}`, });
        }
        return multiples;
    };

    const DropdownItems = generateMultiples(5, 10);

    const updateQuantity = (itemId, event) => {
        // console.log(itemId,event.target.value)
        const value = event.target.value
        // setSelectedOption(value)
        // dispatch(updateCartItemQuantity(itemId, value));
    };

    return (
        <div>
            <div id="bkgOverlay_here" className="backgroundOverlay"></div>
            <div id="delayedPopup_here" className="delayedPopup_hereWindow">
                {/* This is the close button  */}
                <a href="#" id="btnClose_here" title="Click here to close this deal box.">
                    <i className="fa-solid fa-circle-xmark btn_pop_i"></i></a>
                {/* This is the left side of the popup for the description_here  */}
                <div className="formdescription_here">
                    <div className="description_here">
                        <a href="">
                            <img src={popupbanner} style={{ height: 'auto', width: '100%' }} />
                        </a>
                    </div>
                </div>
            </div>
            <div className='top-slider-section'>
                {
                    Data ?
                        <Carousel activeIndex={index} onSelect={handleSelect} interval={3000}>
                            {Data.data.home_slider[1].sliders.map((image, idx) => (
                                <Carousel.Item key={idx}>
                                    <div style={{ width: 1370, height: 300 }}>
                                    <Link to={`/Productdetails/${encodeURIComponent(image.link_product_id)}`}>
                                        <img
                                            style={{ width: '100%', overflow: 'hidden' }}
                                            className="d-block w-100"
                                            src={image.slider_image_url}
                                            alt={`Slide ${idx + 1}`}
                                        />
                                        </Link>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel> : null
                }

            </div>
            <div className="new_catagory__sbilabhide">
                <div className="new_catagory sbilabhide pt-4">
                    <div >
                        <div className="row">
                            <div className="col-md-4 col">
                                <div className="cat-list">
                                    <span className="car-imgs">
                                        <img src={Wellnessnew} />
                                    </span>
                                    <span className="cat-contain-txt">
                                        <span className="heading">
                                            Authenticity of Medicine
                                        </span>
                                        <span className="save_text">
                                            Ace Healthcare
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 col">
                                <div className="cat-list">
                                    <span className="car-imgs">
                                        <img src={Beautynew} />
                                    </span>
                                    <span className="cat-contain-txt">
                                        <span className="heading">
                                            Commitment of Services
                                        </span>
                                        <span className="save_text">
                                            Ace Healthcare
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 col">
                                <div className="cat-list">
                                    <span className="car-imgs">
                                        <img src={Wellnessnew} />
                                    </span>
                                    <span className="cat-contain-txt">
                                        <span className="heading">
                                            Excellence of Quality
                                        </span>
                                        <span className="save_text">
                                            Ace Healthcare
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="medial-silder">
                <div className="home Trending">
                    <h2> Trending Today </h2>
                    <div>
                        <div className='container-fluid text-center ' >
                            {Data ?
                                <OwlCarousel items={2} margin={10} autoplay={true} >
                                    {
                                        Data.data.home_slider[0].sliders.map((item, index) => (
                                            <div key={index}>
                                                <div style={{ height: 200 }}>
                                                <Link to={`/Productdetails/${encodeURIComponent(item.link_product_id)}`}>
                                                    <img className="img" src={item.slider_image_url} />
                                                    </Link>
                                                </div>
                                                <span className="heading" style={{ color: '#fff' }}>{item.description}</span>
                                            </div>
                                        ))
                                    }
                                </OwlCarousel> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* cat-product-section Start  */}
            <div className="pendding"></div>
            <section className="Best Deals on-slider-section">
                <div className="xmtrr">
                    <div className="cat-product-section">
                        <div className="heading-cat-product">
                            <div className="row">
                                <div className="col-md-6 col"><h2>Featured Products</h2></div>
                                <div className="col-md-6 col"><Link to={"/feature-products"}><p>View All</p> </Link></div>
                            </div>
                        </div>
                    </div>
                    <div >
                        {Data ?
                            <OwlCarousel items={3} autoWidth={261} margin={8} autoplay={true} >
                                {Data.data.feature_products.map((item) => (
                                    <div key={item.id} className="item" style={{ width: 260, }}>
                                        <div className="card_beauty-top-products">
                                            <Link to={`/Productdetails/${encodeURIComponent(item.id + '/' + item.pruduct_name)}`}>
                                                <div className="card_beauty-top-products-image">
                                                    <img src={item.product_image} alt="Product" />
                                                </div>
                                                <span className="cat-product-titel">{item.pruduct_name}</span>
                                            </Link>
                                            <span className="price_box">
                                                <span>₹ {item.mrp}</span>
                                                <strike>₹ {item.user_price}</strike>
                                            </span>
                                            {/* <span className="price-box-offer">GET 3 % off</span> */}
                                            <div className="clearfix"></div>
                                            <span className="express_delivery">
                                                <span style={{ display: 'none' }} className="express_content 1065653" id="1065653">Express delivery</span>
                                            </span>

                                            <div className="cart_btn">
                                                <div className='Selectquantity'>
                                                    <label htmlFor="dropdown" >Qty:</label>
                                                    <div className='Dropdown_input_Box'>
                                                        <input
                                                            // type="number"
                                                            className="quantity-input"
                                                            style={{ height: '100%', border: 0, width: 33, paddingLeft: 4 }}
                                                            value={item.quantity}
                                                        // onChange={(value) => { updateQuantity(product.id, value) }}
                                                        />
                                                        <select
                                                            id="dropdown"
                                                            value={selectedOption}
                                                            // onChange={(value) => { updateQuantity(product.id, value) }}
                                                            style={{ width: 20, border: 0 }}
                                                        >
                                                            {DropdownItems.map((item) => (
                                                                <option key={item.value} >{item.value}</option>
                                                            ))}

                                                        </select>
                                                    </div>
                                                </div>
                                                <button onClick={(event) => handleAddToCart(item, event)} type="submit" title="ADD TO CART" className="toCart cartbag addtocartbtn prodbtn" data-track="yes" data-sku="1065653">ADD TO CART</button>
                                            </div>

                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel> : null
                        }
                    </div>
                </div>
            </section>
            {/* responsive div Start  */}

            <div className="acr-health-store">
                <div className="container">
                    <div className="contactdh">

                        <div className="order-meds-prev" href="#">
                            <span className="acehealthcare_Stores ">ACE  Stores </span>
                            <div className="clearfix"></div>
                            <div className="f-msg lg_green-text">
                                Stores near you
                            </div>
                        </div>
                        <div className="order-meds-prev" href="#">
                            <span className="acehealthcare_Stores ">Generics</span>
                            <div className="clearfix"></div>
                            <div className="f-msg ">
                                Save Upto 60% off
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* home-static-new Div Start  */}

            <div className="New_shop_by_catergry">
                <div className="container">
                    <div className="row">
                        <div className="col">

                            <div className="product-box_with_i">
                                <a href="">
                                    <span className="machines-trit">
                                        <img src="../../images/surgical_instrument_1.jpg" />
                                    </span>
                                    <div className="clearfix"></div>
                                    <span className="produc_cat_name-">Homeopathy</span>
                                </a>
                            </div>
                        </div>
                        <div className="col">

                            <div className="product-box_with_i">
                                <a href="">
                                    <span className="machines-trit">
                                        <img src="../../images/surgical_instrument_1.jpg" />
                                    </span>
                                    <div className="clearfix"></div>
                                    <span className="produc_cat_name-">Homeopathy</span>
                                </a>
                            </div>
                        </div>

                        <div className="col">

                            <div className="product-box_with_i">
                                <a href="">
                                    <span className="machines-trit">
                                        <img src="../../images/surgical_instrument_1.jpg" />
                                    </span>
                                    <div className="clearfix"></div>
                                    <span className="produc_cat_name-">Homeopathy</span>
                                </a>
                            </div>
                        </div>

                        <div className="col">

                            <div className="product-box_with_i">
                                <a href="">
                                    <span className="machines-trit">
                                        <img src="../../images/surgical_instrument_1.jpg" />
                                    </span>
                                    <div className="clearfix"></div>
                                    <span className="produc_cat_name-">Homeopathy</span>
                                </a>
                            </div>
                        </div>

                        <div className="col">
                            <div className="product-box_with_i">
                                <a href="">
                                    <span className="machines-trit">
                                        <img src="../../images/surgical_instrument_1.jpg" />
                                    </span>
                                    <div className="clearfix"></div>
                                    <span className="produc_cat_name-">Homeopathy</span>
                                </a>
                            </div>
                        </div>

                        <div className="col">

                            <div className="product-box_with_i">
                                <a href="">
                                    <span className="machines-trit">
                                        <img src="../../images/surgical_instrument_1.jpg" />
                                    </span>
                                    <div className="clearfix"></div>
                                    <span className="produc_cat_name-">Homeopathy</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* home-static-new Div End   */}
            {/* populur product section start  */}
            <div className="test_pack">
                <div className="clearfix"></div>
                <div className="on-slider-section__">
                </div>

                <div className="scrool">
                    <h2>Popular Tests &amp; Packages
                        <a href="#"> View All</a>
                    </h2>
                    <div className="image_of populer-product">
                        <div className="img__1">
                            <a href="">
                                <img src={product1} />
                            </a>
                            <img src="" />
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src={product1} />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src={product1} />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src={product1} />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                        <div className="img__1">
                            <a href="">
                                <img src="../../images/1663249013_Aarogyam-1.3.jpg" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            {/* responsive div End   */}



            {/* cat-product-section TWO Start  */}
            {/* <div className="pendding"></div> */}
            <section className="Best Deals on-slider-section xmtr">
                <div className="xmtrr">
                    <div className="cat-product-section">
                        <div className="heading-cat-product">
                            <div className="row">
                                <div className="col-md-6 col"><h2>Featured Products</h2></div>
                                <div className="col-md-6 col">
                                    <Link to={"/feature-products"}><p>View All</p> </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            Data ?
                                <OwlCarousel items={3} autoWidth={261} margin={8} autoplay={true} >
                                    {Data.data.feature_products.map((item) => (
                                        <div key={item.id} className="item" style={{ width: 260, }}>
                                            <div className="card_beauty-top-products">
                                                <Link to={`/Productdetails/${encodeURIComponent(item.id + '/' + item.pruduct_name)}`}>
                                                    <div className="card_beauty-top-products-image">
                                                        <img src={item.product_image} alt="Product" />
                                                    </div>
                                                    <span className="cat-product-titel">{item.pruduct_name}</span>
                                                </Link>
                                                <span className="price_box">
                                                    <span>₹ {item.mrp}</span>
                                                    <strike>₹ {item.user_price}</strike>
                                                </span>
                                                {/* <span className="price-box-offer">GET 3 % off</span> */}
                                                <div className="clearfix"></div>
                                                <span className="express_delivery">
                                                    <span style={{ display: 'none' }} className="express_content 1065653" id="1065653">Express delivery</span>
                                                </span>
                                                <div className="cart_btn">
                                                    <div className='Selectquantity'>
                                                        <label htmlFor="dropdown" >Qty:</label>
                                                        <div className='Dropdown_input_Box'>
                                                            <input
                                                                // type="number"
                                                                className="quantity-input"
                                                                style={{ height: '100%', border: 0, width: 33, paddingLeft: 4 }}
                                                                value={item.quantity}
                                                            // onChange={(value) => { updateQuantity(product.id, value) }}
                                                            />
                                                            <select
                                                                id="dropdown"
                                                                value={selectedOption}
                                                                // onChange={(value) => { updateQuantity(product.id, value) }}
                                                                style={{ width: 20, border: 0 }}
                                                            >
                                                                {DropdownItems.map((item) => (
                                                                    <option key={item.value} >{item.value}</option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                    </div>
                                                    <button type='button' onClick={(event) => handleAddToCart(item, event)} title="ADD TO CART" className="toCart cartbag addtocartbtn prodbtn" >ADD to  CART</button>
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel> : null
                        }

                    </div>
                </div>
                {/* cat product conatin Section End  */}

            </section>
            {/* Shop by Category section Start  */}

            <section className="sho_p-catergory">
                <div>
                    <div className="Shop_Category_heading">
                        <div className="row">
                            <div className="col-md-6 col"><h2>Shop by Category</h2></div>
                            <div className="col-md-6 col"><a href="#"><p>View All </p></a></div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="Category-card">
                        {Data ?
                            <OwlCarousel items={3} autoWidth={261} margin={8}  >
                                {Data.data.home_category.map((item) => (
                                    <div key={item.id} className="item" style={{ width: 260, }}>
                                        <div className="card_beauty-top-products" style={{ textAlign: 'center' }}>
                                            <Link to={`/CategoryDetails/${encodeURIComponent(item.id + '/' + item.category_name)}`}>
                                                <div className="card_beauty-top-products-image">
                                                    <img src={item.category_image_url} alt="Product" />
                                                </div>
                                                <span className="cat-product-titel">{item.category_name}</span>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel> : null
                        }
                    </div>
                </div>
            </section>
            {/* Shop by Category section End   */}

            {/* New On ACE Healthcare Contain Start  */}

            <div className="medial-silder">
                <div className="home Trending">
                    <h2>Upcoming Products </h2>
                    <div>
                        {Data ?
                            <OwlCarousel items={2} margin={10} autoplay={true} >
                                {
                                    Data.data.home_slider[2].sliders.map((item, index) => (
                                        <div key={index} className='text-center'>
                                            <div style={{ height: 200 }}>
                                            <Link to={`/Productdetails/${encodeURIComponent(item.link_product_id)}`}>
                                                <img className="img" src={item.slider_image_url} />
                                                </Link>
                                            </div>
                                            <span className="heading" style={{ color: '#fff' }}>{item.description}</span>
                                        </div>
                                    ))
                                }
                            </OwlCarousel> : null
                        }
                        {/* <OwlCarousel items={3} margin={8} autoplay={true} >
                            <div ><img className="img" src={mini_web} /></div>
                            <div><img className="img" src={mini_web} /></div>
                            <div><img className="img" src={mini_web} /></div>
                            <div><img className="img" src={mini_web} /></div>
                            <div><img className="img" src={mini_web} /></div>
                            <div><img className="img" src={mini_web} /></div>
                            <div><img className="img" src={mini_web} /></div>
                        </OwlCarousel> */}
                    </div>
                </div>
            </div>

            {/* responsive section start archealth First Membership  */}
            <section id="fMembers_con_txt">
                <div className="fmember-content">
                    <div className="lazyautosizes_lazyloaded">  <img src="../../images/acthealthstar_members.svg" /> </div>
                    <div className="fmember-rightcontent">
                        <div className="con-text_top">
                            <h2>Acehealthcare First Membership</h2>
                        </div>
                        <div className="con-txt">
                            <ul>
                                <li>Get special discounts and offers on acehealthcare services.</li>
                                <li>Get 2.5% NMS Cash on pre-paid, Medicine orders of ANY value!</li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="bottom">
                    <div className="f-msg">Starting at Rs.299</div>
                    <a href="" className="explore-plans btn-ripple">Explore Plans</a>
                </div>
            </section>
            {/* responsive section End archealth First Membership  */}

            {/* responsive section Start archealth Get medicine refill every month  */}
            <div id="section_113">
                <section id="mRefill" className="static_section">
                    <div className="lSection">
                        <h2>Get medicine refill every month</h2>
                        <ul>
                            <li>Subscribe and schedule next deliveries</li>
                            <li>Subscribe for 12 months and get the last month free. *T&amp;C Apply</li>
                        </ul>
                        <a className="view_btn btn-ripple" href="">Subscribe</a>
                    </div>
                    <div className="rSection"><img src="../../images/subscribe_and_schedule.svg" sizes="94px" /></div>
                    <div className="clearfix"></div>
                </section>
                <div className="clearfix"></div>
            </div>



            {/* responsive section End archealth Get medicine refill every month */}
            <div id="section_113">
                <section id="mRefill" className="static_section">
                    <div className="lSection">
                        <h2>Refer and Earn</h2>
                        <ul>
                            <li>Invite friends to increase your wallet balance.</li>
                        </ul>
                        <a className="view_btn btn-ripple" href="">Invite friends</a>
                    </div>
                    <div className="rSection"><img src="../../images/refer_and_earn.svg" sizes="94px" /></div>
                    <div className="clearfix"></div>
                </section>
                <div className="clearfix"></div>
            </div>
            {/* New On ACE Healthcare Contain End  */}

            {/* grab Our TOP Picks section Start */}
            <div id="section_113">
                <div className="Grab Our TOP_Picks"></div>
                <div className="row">
                    <button className="accordion st"><div className="question"> <h2>Grab Our TOP Picks</h2> </div></button>
                    <div className="panel">
                        <p className="answer">
                            <b>Popular Wellness Products:</b>
                            <a href="">Dabur Shilajit Gold Capsule 20's</a> |
                            <a href="">Dabur Chyawanprash Awaleha 500 gm</a> |
                            <a href="">Pankajakasthuri Breathe Easy Granules 400 gm</a> |
                            <a href="">Nestle Peptamen Peptide Based Diet Powder - Vanilla Flavour 400 gm (Tin)</a> |
                            <a href="">Pentasure 2.0 Vanilla Powder 1 kg</a> |
                            <a href="">Scalpe Plus Anti Dandruff Shampoo 75 ml</a> |
                            <a href="">Nestle Nan Excella Pro 1 (Upto 6 Months) Powder 400 gm (Refill Pack)</a> |
                            <a href="">Accu-Chek Active Test Strips 50's</a> |
                            <a href="">D Protin Chocolate Powder 500 gm</a> |
                            <a href="">Climax Spray for Men 12 gm</a>
                            <br />
                            <b>Top-Selling Health Packages:</b>
                            <a href="">AAROGYAM C</a> |
                            <a href="">acehealthcare Health Pack</a> |
                            <a href="">Aarogyam 1.3</a> |
                            <a href="">acehealthcare Swasthya</a> |
                            <a href="s">Diabetic Checkup</a> |
                            <a href="">Aarogyam 1.7</a> |
                            <a href="s">Basic Allergy Package</a> |
                            <a href="">Aarogyam X</a> |
                            <a href="/-">Advance Heart Care</a> |
                            <a href="/-">acehealthcare Swasthya Plus</a>
                            <br />
                            <b>Top-Selling Lab Tests:</b>
                            <a href="/-">Complete Blood Count (CBC)/Complete Hemogram</a> |
                            <a href="/-">LIVER FUNCTION TEST</a> |
                            <a href="/-">Blood Glucose Fasting (FBS)</a> |
                            <a href="/-">THYROID PROFILE -TOTAL(T3,T4&amp;TSH)</a> |
                            <a href="/-">Fever Profile</a> |
                            <a href="/-]">Urine Routine &amp; Microscopy</a> |
                            <a href="/-">Hemoglobin A1C (HbA1c)</a> |
                            <a href="/-">25-OH Vitamin D (TOTAL)/Vitamin D Total 25 Hydroxy</a> |
                            <a href="/-">Uric Acid</a> |
                            <a href="/-">Maternal screen-1st Trimester Dual Marker test</a>
                            <br />
                            <b>Top-Selling Radiology Tests:</b>
                            <a href="s">Ultrasound Whole Abdomen</a> |
                            <a href="">MRI Scan Brain</a> |
                            <a href="">CT Scan Brain</a> |
                            <a href="">ECHO</a> |
                            <a href="">Arogyavardhini Vati - Uses, Functions And Therapeutic Benefits</a> |
                            <a href="">Amazing Benefits Of Betel Leaves Nobody Told You</a>
                        </p>
                        <h1>Welcome to acehealthcare.com! India's Leading Online Pharmacy!</h1>
                        <p>With a dynamic legacy of over 20+ years in the pharma business, it comes as no surprise that acehealthcare.com is the first choice of over 4 million+ satisfied customers when it comes to an online pharmacy in India. acehealthcare.com has a pan India presence as we deliver health care essentials to every state in the country. We take your health seriously at acehealthcare.com. Be it purchasing medicines online, lab tests or online doctor consultations, we've got it all covered for our customers!</p>
                        <h1>Take the Worry Out of Buying Medicines! Purchase Medicines Online Anytime, Anywhere!</h1>
                        <p>Get everything you need at acehealthcare.com to take care of your health right from high-quality, affordable, authentic prescription medicines, Over-The-Counter pharmaceuticals products to general health care products, Ayurveda, Unani and Homeopathy medicines.</p>
                        <p>Buy medicines online at acehealthcare.com from the comfort of your home and we will take care of the rest! We will ensure that the life-saving drugs reach your doorstep without a hitch. Do away with the hassle of driving to the medical store, waiting in line, or even remembering your refills! acehealthcare.com will sort out those problems for you effectively so that you can lead a healthy and full life!</p>
                        <p>Ordering medicines online at acehealthcare.com is just a simple 4 step process. Browse through our wide range of health care products, add them to your cart, uploading your prescription (if required) and proceed to checkout!</p>
                        <p>With acehealthcare.com, rest assured that your health will be in safe hands!</p>
                        <h1>Buying medicines online</h1>
                        <p>At acehealthcare.com, we ensure that you get high-quality life-saving medicines are delivered to you on time. Order medicines online at your convenience from across the country. We also deliver Ayurvedic, Homeopathic, Unani and Over-The-Counter (OTC) products to over 19,000 pin codes across the country!</p>
                        <h1>Medicine Subscription</h1>
                        <p>Remembering to refilling medicines month on month to address chronic conditions can be a hassle. acehealthcare' subscription service will ensure that you never run out of these vital medicines. Just set it up and your medicines will get refilled and delivered automatically every month to your doorstep!</p>
                        <h1>acehealthcare First</h1>
                        <p>acehealthcare First is our loyalty programme which puts you and your health First! First members can get an extra 2.5% NMS Cashback (up to INR 100 per order) on prescription medication ordered using the membership. Members are also eligible for free delivery, priority processing, and free online doctor consultations!</p>
                        <h1>Diagnostics</h1>
                        <p>Do you find yourself constantly putting off getting your lab tests done because it's inconvenient to schedule them or because they are expensive? Visit acehealthcare.com to book lab tests and health checkup packages online from well-reputed, certified diagnostic labs according to your convenience and at affordable rates. We will also make your lab reports available online for easy access.</p>
                        <h1>Online Doctor Consultation</h1>
                        <p>Are the long queues at hospitals and clinics putting you off? At acehealthcare.com, we realize that your time and health are precious. Consult with top-notch doctors online 24/7 at your convenience through our online doctor consultation service!</p>
                        <h1>Why Choose acehealthcare?</h1>
                        <ul>
                            <li>20+ years of experience in the pharma sector</li>
                            <li>Vital medicines delivered across the country</li>
                            <li>Trust of more than 4 million+ loyal customers</li>
                            <li>Our team is made up of highly experienced pharmacists &amp; healthcare professionals</li>
                            <li>A wide array of healthcare services available for your convenience</li>
                            <li>We stock only genuine medicines &amp; healthcare products</li>
                        </ul>
                        <h1>Our Awards and Recognition</h1>
                        <p>We at acehealthcare.com know how crucial medicine is to treating health conditions and we are extremely proud to be at the forefront of the online medicine industry. acehealthcare.com offers its customers a reliable online pharmacy service and as a testament to our commitment, we are regularly recognized and honoured with awards. We are pleased to highlight some of our awards here and we aim to continue adding more feathers to our cap!</p>
                    </div>
                </div>
            </div>
            <section>
                <div>
                    <div className="mobile-bottom-bar">
                        <a href="/" id="fHome" className="footer-link active btn-ripple">
                            <span className="fhome-icon"></span>
                            <span className="footer-text">Home</span>
                        </a>
                        <a href="/" id="fOrder" className="footer-link btn-ripple">
                            <span className="forder-icon"></span>
                            <span className="footer-text">Order</span>
                        </a>
                        <a href="/" id="fBeauty" className="footer-link btn-ripple">
                            <span className="fbeauty-icon"></span>
                            <span className="footer-text">Beauty</span>
                        </a>
                        <a href="/" id="fWellness" className="footer-link btn-ripple">
                            <span className="fwellness-icon"></span>
                            <span className="footer-text">Wellness</span>
                        </a>
                        <a href="/" id="fAccount" className="footer-link btn-ripple">
                            <span className="fpro-icon"></span>
                            <span className="footer-text">Account</span>
                        </a>
                    </div>

                </div>
            </section>
            <section className="Chooseness_ACE Healthcare">
                {/* Why Choose ACE Healthcare? Contain Start   */}

                {/* <div className="home-welcome-txt">
                    <h1>Why Choose ACE Healthcare?</h1>
                    <ul>
                        <li>20+ years of experience in the pharma sector</li>
                        <li>Vital medicines delivered to customers</li>
                        <li>Trust of satisfied loyal customers</li>
                        <li>Our team is made up of highly experienced pharmacists &amp; healthcare professionals</li>
                        <li>A wide array of healthcare services available for your convenience</li>
                        <li>We stock only genuine medicines &amp; healthcare products</li>
                    </ul>
                    <h1>Our Awards and Recognition</h1>
                    <p>We at ACE Healthcare.com know how crucial medicine is to treating health
                        conditions and we are extremely proud to be at the forefront of
                        the online medicine industry. ACE Healthcare.com offers its customers a
                        reliable online pharmacy service and as a testament to our commitment,
                        we are regularly recognized and honoured with awards. We are pleased
                        to highlight some of our awards here and we aim to continue adding
                        more feathers to our cap!</p>
                </div> */}

                {/* Why Choose ACE Healthcare? Contain End   */}
            </section>
        </div>
    )
}
